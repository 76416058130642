<template>
  <PageView>
    <StatsTopNavBar
      modal-title="AR - Advanced Filters"
      :tab-items="navBarTabs"
      :selected-tab="activeTab"
      :filters-applied="arStatsStore.filtersApplied"
    >
      <template #modal-content>
        <ARFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex flex-col gap-8">
      <div class="flex gap-2 items-center mb-2 justify-between">
        <div class="flex items-center gap-2">
          <h1 class="text-rev-2xl text-primary font-bold">
            AR {{ activeTab }}
          </h1>
          <div
            class="relative flex flex-col items-center justify-center bg-tertiary rounded-xl text-white w-[1.25rem] h-[1.25rem]"
            @mouseenter="openYellowPopup"
            @mouseleave="hideYellowPopup"
          >
            <div
              v-if="showYellowPopup"
              class="absolute right-[-24.5rem] z-10 text-sm whitespace-nowrap flex flex-col items-center justify-center bg-white border-2 border-solid border-tertiary rounded-xl text-black w-fit px-2 py-1"
            >
              Date and Balance Filters cannot be applied to this page.
            </div>
            !
          </div>
        </div>
        <div class="flex items-center gap-1">
          <span class="text-rev-sm text-gray-500 font-bold"
            >Current AR Date:</span
          >
          <span class="text-rev-sm text-gray-400">{{ currentARDate }}</span>
        </div>
      </div>
      <div class="bg-white rounded-lg p-6">
        <div class="flex gap-2 items-center">
          <h2 class="font-semibold text-2xl items-start">Overview</h2>
          <div
            class="flex flex-col items-center justify-center bg-gray-200 rounded-full text-white w-5 h-5 relative"
            @mouseenter="openBluePopup"
            @mouseleave="hideBluePopup"
          >
            <div
              v-if="showBluePopup"
              class="w-fit text-black border border-secondary rounded-xl bg-white whitespace-nowrap px-2 py-1 absolute left-7"
            >
              High Dollar settings are determined by an Administrator.
            </div>
            <InfoIcon />
          </div>
        </div>
        <div class="flex w-full gap-6 pt-8">
          <div class="flex flex-col gap-3 w-1/5">
            <div
              class="flex flex-col gap-1"
              v-for="(entry, index) in arStatsStore.highDollarOverview"
              :key="index"
            >
              <span>{{ entry.name }}</span>
              <span class="text-gray-400">{{
                entry.type == 'currency'
                  ? formatCurrency('en-US', false).format(entry.value)
                  : entry.value
              }}</span>
            </div>
          </div>
          <div
            v-for="(entry, index) in arStatsStore.highDollarCards"
            :key="index"
            class="bg-gray-200 rounded-lg flex flex-col items-center justify-between w-1/5"
          >
            <div
              class="text-white rounded-t-lg w-full py-2 text-center text-lg"
              :class="{
                ['bg-gray-600']: entry.cardColorTernary == 0,
                ['bg-chart-teal']: entry.cardColorTernary == 1,
                ['bg-chart-yellow']: entry.cardColorTernary == 2,
                ['bg-bad-red ']: entry.cardColorTernary == 3,
              }"
            >
              {{ entry.cardTitle }}
            </div>
            <div
              class="flex flex-col items-center"
              v-for="(key, index) in Object.keys(entry.displayedValues)"
              :key="index"
            >
              <StatsValue
                :value="entry.displayedValues[key]"
                :color-ternary="4"
                :percentage="key == 'percentage'"
                :currency="key == 'balance'"
                size-classes="text-xl"
              />
              <span class="text-gray-500">{{
                key[0].toUpperCase() + key.substring(1)
              }}</span>
            </div>
            <div class="invisible"></div>
          </div>
        </div>
      </div>
      <div class="rounded-lg bg-white p-6">
        <div class="flex justify-between">
          <h2 class="font-semibold text-2xl items-start">
            Total High Dollar Work Items
          </h2>
          <div class="flex gap-4">
            <ToggleComponent
              left-button-text="Financial Class"
              right-button-text="Age Category"
              @toggle="handleChangeCategories"
            />
            <ToggleComponent
              left-button-text="Stack 100"
              right-button-text="Column Chart"
              @toggle="
                button => {
                  isStacked = button == 'left';
                }
              "
            />
          </div>
        </div>
        <hr class="h-px my-10 bg-gray-200 border-0 dark:bg-gray-700" />

        <div v-if="loading" class="flex justify-center">
          <LoadingSpinner />
        </div>
        <div v-else class="flex flex-col gap-4">
          <ChartComponent
            chart-id="high-dollar-chart"
            :chart-data="chartData"
            :stack100="isStacked"
            :color-override="[RED, UGLY_YELLOW, CYAN]"
          />
          <!-- Table -->
          <div
            class="flex flex-col-reverse bg-white p-4 rounded-lg max-w-full overflow-x-scroll relative"
            :style="{ transform: 'rotateX(180deg)' }"
          >
            <div class="w-fit" :style="{ transform: 'rotateX(180deg)' }">
              <table
                class="text-sm text-left text-gray-500 ![--tw-drop-shadow:none]"
              >
                <thead class="text-sm bg-white">
                  <tr>
                    <template
                      v-for="(header, index) in fakeHeaders"
                      :key="index"
                    >
                      <th
                        v-if="header.key == 'status'"
                        class="px-4 py-3 bg-gray-100"
                      >
                        <DropdownRadio
                          button-text="Status"
                          :dropdown-options="[
                            { value: 'all', text: 'All' },
                            {
                              value: 'Needs Attention',
                              text: 'Needs Attention',
                            },
                            {
                              value: 'Recently Worked',
                              text: 'Recently Worked',
                            },
                            {
                              value: 'Recently Billed',
                              text: 'Recently Billed',
                            },
                          ]"
                          default-selected="Needs Attention"
                          @change="handleStatusChange"
                        />
                      </th>
                      <TableHeader
                        v-else
                        :id="header.key.toString()"
                        :title="header.value"
                        :show-sort="header.sort"
                        :width="header.width"
                        :style="header.style"
                        class="px-4 py-3 bg-gray-100"
                        :showArrows="true"
                        @sort="sortFields"
                      />
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(item, index) in chartData.tableData"
                    :key="index"
                  >
                    <tr :data-index="index" class="border-b">
                      <td
                        v-for="(header, tableDataIndex) in fakeHeaders"
                        :key="tableDataIndex"
                        class="ml-1 px-4"
                      >
                        <StatsTableCell
                          :item="item"
                          :status-indicator="tableDataIndex == 0"
                          :status-color-map="{
                            'Needs Attention': 'bg-bad-red',
                            'Recently Billed': 'bg-chart-teal',
                            'Recently Worked': 'bg-chart-yellow',
                          }"
                          :div-classes="
                            tableDataIndex == 0 ? 'justify-center' : ''
                          "
                          :header="header"
                          :width="header.width"
                        />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="flex pt-4 justify-between items-center">
          <StatsPagination
            :records="chartData.totalRows"
            :limit="pageLimit"
            :page="pageNumber"
            @paginate="paginate"
          />
        </div>
      </div>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import ARFilterModal from '@/components/stats/modals/ARFilterModal.vue';
import { useArStatsStore } from '@/stores/stats/useARStats';
import InfoIcon from '@/assets/new-stats/InfoIcon.vue';
import { onMounted, ref } from 'vue';
import { formatCurrency } from '@/utils/helpers';
import StatsValue from '@/components/stats/Misc/StatsValue.vue';
import ToggleComponent from '@/components/stats/Misc/ToggleComponent.vue';
import ChartComponent from '@/components/stats/ChartComponent.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import StatsPagination from '@/components/stats/table/StatsPagination.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import StatsTableCell from '@/components/stats/table/StatsTableCell.vue';
import { arDetailHeaders } from './constants';
import DropdownRadio from '@/components/stats/Misc/DropdownRadio.vue';
import { CYAN, RED, UGLY_YELLOW } from '@/utils/constants';

const arStatsStore = useArStatsStore();

const activeTab = 'High Dollar';
const currentARDate = arStatsStore.lastUpdated;

const showYellowPopup = ref(false);
const showBluePopup = ref(false);
const isStacked = ref(true);
const series = ref('rsn');
const searchDirection = ref('asc');
const filter = ref('Needs Attention');
const loading = ref(true);
const chartData = ref([]);
const fakeHeaders = ref([]);
const category = ref('finClass');

const pageNumber = ref(1);
const pageLimit = 10;

onMounted(async () => {
  chartData.value =
    await arStatsStore.createSeriesAndCategoriesForHighDollarChart(
      pageLimit.value,
      pageNumber.value,
      searchDirection.value,
      series.value
    );

  loading.value = false;

  if (arDetailHeaders[0].key !== 'status') {
    arDetailHeaders.unshift({
      key: 'status',
      value: 'Status',
      width: '16rem',
      sort: false,
    });
  }

  fakeHeaders.value = arDetailHeaders;
});

function openYellowPopup() {
  showYellowPopup.value = true;
}

function hideYellowPopup() {
  showYellowPopup.value = false;
}

function openBluePopup() {
  showBluePopup.value = true;
}

function hideBluePopup() {
  showBluePopup.value = false;
}

async function paginate(page) {
  pageNumber.value = page;
  const response =
    await arStatsStore.createSeriesAndCategoriesForHighDollarChart(
      pageLimit.value,
      pageNumber.value,
      searchDirection.value,
      series.value,
      filter.value,
      category.value
    );
  chartData.value.tableData = response.tableData;
}

async function handleStatusChange(status) {
  filter.value = status;
  pageNumber.value = 1;

  const response =
    await arStatsStore.createSeriesAndCategoriesForHighDollarChart(
      pageLimit.value,
      1,
      searchDirection.value,
      series.value,
      filter.value,
      category.value
    );

  chartData.value = response;
}

async function handleChangeCategories(categorySwap) {
  const categoryThing = categorySwap == 'left' ? 'finClass' : 'ageCategory';
  category.value = categoryThing;
  const response =
    await arStatsStore.createSeriesAndCategoriesForHighDollarChart(
      pageLimit.value,
      pageNumber.value,
      searchDirection.value,
      series.value,
      filter.value,
      categoryThing
    );

  chartData.value = response;
}

async function sortFields(order = 'asc', field) {
  searchDirection.value = order;
  series.value = field;
  const response =
    await arStatsStore.createSeriesAndCategoriesForHighDollarChart(
      pageLimit.value,
      pageNumber.value,
      order,
      field,
      filter.value,
      category.value
    );

  chartData.value.tableData = response.tableData;
}
</script>
<style scoped></style>
