<template>
  <div v-if="isLoading" class="flex justify-center">
    <LoadingSpinner />
  </div>
  <div class="relative">
    <div :id="`${props.chartId}-legend`" class="sticky top-0"></div>

    <div class="overflow-x-auto max-w-full">
      <div :id="props.chartId"></div>
    </div>
  </div>
</template>

<script setup>
import {
  CYAN,
  GRAY_400,
  PINK,
  PRIMARY,
  PURPLE,
  RUST,
  UGLY_YELLOW,
  RED,
} from '@/utils/constants';
import ApexCharts from 'apexcharts';
import { onMounted, watch } from 'vue';
import { ref } from 'vue';
import LoadingSpinner from '../LoadingSpinner.vue';

const CATEGORY_LIMIT = 20;

const props = defineProps({
  stack100: {
    type: Boolean,
    default: false,
  },
  monthView: {
    type: Boolean,
    default: true,
  },
  chartData: {
    type: Object,
    default: () => {},
  },
  chartId: {
    type: String,
    default: '',
  },
  colorOverride: {
    type: Array,
    default: () => [],
  },
  currency: {
    type: Boolean,
    default: false,
  },
});

const COLOR_MAPPINGS = {
  1: [PRIMARY],
  2: [UGLY_YELLOW, RED],
  5: [PURPLE, CYAN, UGLY_YELLOW, RUST, PRIMARY],
  6: [PINK, PURPLE, CYAN, UGLY_YELLOW, RUST, PRIMARY],
};
const chartRef = ref(null);
const isLoading = ref(false);

const chartOptions = () => {
  return {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: props.stack100 ? '100%' : 'normal',
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      width:
        props.chartData.categories.length < CATEGORY_LIMIT ? '100%' : '1000%',
      height: '400px',
    },
    colors:
      props.colorOverride.length > 0
        ? props.colorOverride
        : COLOR_MAPPINGS[props.chartData.series.length] ?? COLOR_MAPPINGS[5],

    dataLabels: {
      enabled: props.chartData.series.length > 1,
      formatter: val =>
        props.stack100
          ? val.toFixed() + '%'
          : props.currency
          ? '$' + val.toFixed()
          : val,
    },
    grid: {
      show: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      labels: {
        colors: GRAY_400,
      },
      markers: {
        shape: ['circle'],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusApplication: 'end', //this doesn't work ?
        columnWidth: '90%',
        dataLabels: {
          total: {
            enabled: !props.stack100,
            formatter: val =>
              props.stack100
                ? val
                : val == 0
                ? ''
                : props.currency
                ? '$' + val.toFixed()
                : val.toFixed() + ' total',
          },
        },
      },
    },
    series: props.chartData.series,
    tooltip: {
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex }) => {
          const seriesList = series.map(s => s[dataPointIndex]);
          const total = seriesList.reduce((sum, num) => sum + num, 0);
          return props.stack100
            ? total
              ? ((seriesList[seriesIndex] / total) * 100).toFixed() + '%'
              : '0%'
            : props.currency
            ? '$' + value
            : value;
        },
      },
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      categories: props.chartData.categories,
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          colors: GRAY_400,
        },
      },
    },
    yaxis: {
      max: props.stack100 ? 100 : undefined,
      labels: {
        formatter: val => (props.stack100 ? val.toFixed() + '%' : val),
        style: {
          colors: GRAY_400,
        },
      },
    },
  };
};

onMounted(() => {
  chartRef.value = new ApexCharts(
    document.querySelector(`#${props.chartId}`),
    chartOptions()
  );
  requestAnimationFrame(() => {
    chartRef.value.render();
  });
});

const doSomeJankyLegendMagic = () => {
  //We have to get creative and move the legend out so it can be sticky when theres a lot of data
  //You lose the cool hover effects after moving this :(

  const legendContainer = document.getElementById(`${props.chartId}-legend`);
  let legend = document.querySelector(`#${props.chartId} .apexcharts-legend`);

  //Clear any existing moved legend
  if (legendContainer) {
    legendContainer.innerHTML = '';
    legendContainer.style.paddingBottom = '0px';
  }

  //Move the legend
  if (props.chartData.categories.length > CATEGORY_LIMIT) {
    if (legend && legendContainer) {
      legendContainer.style.paddingBottom = '40px';
      legendContainer.appendChild(legend);
    }
  }
};

const updateChartOptions = async () => {
  if (!chartRef.value) return;

  await chartRef.value.updateOptions(
    chartOptions(),
    true, //redraw paths
    false //animations
  );

  doSomeJankyLegendMagic();
};

// When user updates month/day view toggle or 100% toggle
watch(
  [
    () => props.stack100,
    () => props.chartData.series,
    () => props.chartData.categories,
  ],
  async () => {
    isLoading.value = true;
    await updateChartOptions();
    isLoading.value = false;
  },
  { deep: true }
);
</script>

<style scoped></style>
