<template>
  <PageVue>
    <h1 class="text-rev-2xl text-primary font-bold mb-8">Report Settings</h1>
    <div class="flex flex-col bg-white p-4 w-full">
      <h2 class="text-xl font-bold">High Dollar Thresholds</h2>
      <hr class="h-px my-10 bg-gray-200 border-0 dark:bg-gray-700" />
      <div class="grid grid-cols-3 gap-4 xl:grid-cols-5 mb-8 items-start">
        <BaseInputFlowBite
          v-for="field in fields"
          :key="field.id"
          :label="field.label"
          variant="secondary"
          :type="field.type"
          wrapper-class="w-full"
          :model-value="
            field.type == 'currency'
              ? thresholds[field.id]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : thresholds[field.id].toString()
          "
          :step="field.step"
          :blur="validateNumbers"
          :error="errors[field.id].error"
          :error-message="errors[field.id].message"
          @update:model-value="value => updateField(field.id, value)"
        />
      </div>
      <div class="flex flex-row w-[50%] xl:w-[35%] self-end gap-2">
        <OutlinedButtonFlowBite
          class="text-gray-900 !font-bold"
          @click="resetToDefault"
          >Revert to Default</OutlinedButtonFlowBite
        >
        <SolidButtonFlowBite
          variant="tertiary"
          class="!font-bold"
          @click="applyChanges"
          >Apply Changes</SolidButtonFlowBite
        >
      </div>
    </div>
  </PageVue>
</template>

<script setup>
import OutlinedButtonFlowBite from '@/components/buttons/OutlinedButtonFlowBite.vue';
import SolidButtonFlowBite from '@/components/buttons/SolidButtonFlowBite.vue';
import BaseInputFlowBite from '@/components/forms/BaseInputFlowBite.vue';
import PageVue from '@/components/PageVue.vue';
import { useArStatsStore } from '@/stores/stats/useARStats';
import { reactive } from 'vue';

const arStatsStore = useArStatsStore();

// Field definitions
const fields = [
  {
    id: 'hospitalHighDollar',
    label: 'Hospital High Dollar',
    type: 'currency',
    step: '.01',
    defaultValue: 50000,
  },
  {
    id: 'physicianHighDollar',
    label: 'Physician High Dollar',
    type: 'currency',
    step: '.01',
    defaultValue: 3000,
  },
  {
    id: 'numberOfDaysFromWorked',
    label: 'Number of Days From Worked',
    type: 'number',
    step: '1',
    defaultValue: 30,
  },
  {
    id: 'numberOfDaysFromService',
    label: 'Number of Days From Service',
    type: 'number',
    step: '1',
    defaultValue: 30,
  },
  {
    id: 'numberOfDaysFromSubmission',
    label: 'Number of Days From Submission',
    type: 'number',
    step: '1',
    defaultValue: 30,
  },
];

// Unified thresholds and errors using reactive objects
const thresholds = reactive({
  hospitalHighDollar: arStatsStore.hospitalHighDollarThreshold,
  physicianHighDollar: arStatsStore.physicianHighDollarThreshold,
  numberOfDaysFromWorked: arStatsStore.numberOfDaysFromWorkedThreshold,
  numberOfDaysFromService: arStatsStore.numberOfDaysFromServiceThreshold,
  numberOfDaysFromSubmission: arStatsStore.numberOfDaysFromSubmissionThreshold,
});

// Initialize errors
const errors = reactive(
  Object.fromEntries(
    fields.map(field => [field.id, { error: false, message: '' }])
  )
);

const updateField = (fieldId, value) => {
  thresholds[fieldId] = value;
  clearErrors();
};

const resetToDefault = () => {
  // Reset all fields to their default values
  fields.forEach(field => {
    thresholds[field.id] = field.defaultValue;
    arStatsStore[`${field.id}Threshold`] = field.defaultValue;
  });
  clearErrors();
};

const applyChanges = () => {
  if (!validateNumbers()) {
    return false;
  }

  // Apply all changes to store
  fields.forEach(field => {
    arStatsStore[`${field.id}Threshold`] = thresholds[field.id];
  });
};

const validateNumbers = () => {
  let hasErrors = false;

  // Validate all fields
  fields.forEach(field => {
    if (thresholds[field.id] < 0) {
      errors[field.id] = {
        error: true,
        message: 'Value must be greater than or equal to 0',
      };
      hasErrors = true;
    }
    if (!thresholds[field.id]) {
      errors[field.id] = {
        error: true,
        message: 'This field cannot be left blank',
      };
      hasErrors = true;
    }
  });

  if (!hasErrors) {
    clearErrors();
  }

  return !hasErrors; // Return true if validation passes
};

const clearErrors = () => {
  // Clear all errors
  fields.forEach(field => {
    errors[field.id] = { error: false, message: '' };
  });
};
</script>
