import { defineStore } from 'pinia';
import {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
  PAYER_PLAN_OPTIONS,
  PHASE_STATUS_REASON_OPTIONS,
  ACTIVITY_TYPE_OPTIONS,
  BILLING_ENTITY_OPTIONS,
  FINANCIAL_CLASS_OPTIONS,
  fetchAndSortActivityData,
  FAKE_ACTIVITY_DATA,
} from '@/utils/ogFakeData';

export const useActivityStatsStore = defineStore('activityStats', {
  state: () => ({
    searchLimit: 15,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'date',
    totalRows: 0,

    fakeActivityData: [],
    OGFakeActivityData: FAKE_ACTIVITY_DATA,

    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
    lastUpdated: '10/31/2024',

    selectedUser: [],
    selectedBillingType: [],
    selectedActivityType: [],
    selectedPhaseStatusReason: [],
    selectedPayerPlan: [],
    selectedFinancialClass: [],
    selectedBillingEntity: [],
    filtersApplied: [],
    currentMonth: 'October 2025',
    monthlyTotal: { value: 27 },
    previousSixMonthsAverage: { value: 22 },
    monthlyVS6MonthAverageCount: {
      value: 5,
      colorTernary: 1,
      caretTernary: 2,
    },
    monthlyVS6MonthAveragePercent: {
      value: '22.7%',
      colorTernary: 2,
      caretTernary: 1,
    },
    monthlyInsights: [
      {
        key: 'highestMonth',
        value: 23,
        textValue: 'August 2024',
        labelText: 'Highest Month:',
        colorTernary: 1,
      },
      {
        key: 'lowestMonth',
        value: 5,
        textValue: 'October 2024',
        labelText: 'Lowest Month:',
        colorTernary: 2,
      },
      {
        key: 'currentMonth',
        value: 42,
        textValue: 'October 2024',
        labelText: 'Current Month:',
        colorTernary: 0,
        showWarning: true,
      },
    ],
    mostActiveDays: [
      { value: 21, textValue: 'Monday' },
      { value: 15, textValue: 'Tuesday' },
      { value: 12, textValue: 'Friday' },
    ],
    leaderboard: [
      { username: 'Diane', total: '15' },
      { username: 'Casey', total: '14' },
      { username: 'Robert', total: '13' },
      { username: 'Dan', total: '11' },
      { username: 'Sam', total: '10' },
      { username: 'Nirav', total: '9' },
      { username: 'Tom', total: '9' },
      { username: 'Jeremy', total: '8' },
      { username: 'Jaideep', total: '7' },
      { username: 'Weston', total: '6' },
    ],
    activityCodes: [
      { codeName: 'Appeal Sent', total: '17' },
      { codeName: 'Adjustment - Requested', total: '16' },
      { codeName: 'Billed - Corrected Claim/Rebill', total: '14' },
      { codeName: 'Pending - Appeal in Process', total: '12' },
      { codeName: 'Triaged - Coding', total: '10' },
    ],
    isLoading: false,
    //For modal
    activityTypeOptions: ACTIVITY_TYPE_OPTIONS,
    billingEntityOptions: BILLING_ENTITY_OPTIONS,
    financialClassOptions: FINANCIAL_CLASS_OPTIONS,
    payerPlanDDOptions: PAYER_PLAN_OPTIONS,
    phaseStatusReasonOptions: PHASE_STATUS_REASON_OPTIONS,
  }),
  getters: {
    getFiltersApplied: state => {
      return state.filtersApplied;
    },
  },
  actions: {
    async fetchAndSortActivityStatsData(
      page = 1,
      direction = 'asc',
      field = 'user'
    ) {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };

      try {
        const response = await fetchAndSortActivityData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeActivityData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
    resetFilters() {
      this.selectedUser = [];
      this.selectedActivityGap = [];
      this.selectedUser = [];
      this.selectedBillingType = [];
      this.selectedActivityType = [];
      this.selectedPhaseStatusReason = [];
      this.selectedPayerPlan = [];
      this.selectedFinancialClass = [];
      this.selectedBillingEntity = [];
      this.startDate = DEFAULT_START_DATE;
      this.endDate = DEFAULT_END_DATE;
      this.filtersApplied = [];
    },
    applyFilters() {
      //There will be more here but this will do for now
      this.filtersApplied = [
        { name: 'Date Range Applied' },
        { name: 'Billing Entity Filter Applied' },
      ];
    },

    createSeriesAndCategoriesForChart(
      splitBy = null,
      pageLimit = 5,
      pageNumber = 1,
      columnId = null,
      direction = null
    ) {
      const currentDate = new Date();
      const sixMonthsAgo = new Date(
        currentDate.setMonth(currentDate.getMonth() - 6)
      );
      const categoriesList = [];

      for (let i = 0; i < 7; i++) {
        const monthDate = new Date(sixMonthsAgo);
        monthDate.setMonth(monthDate.getMonth() + i);

        const monthName = monthDate.toLocaleString('default', {
          month: 'short',
        });
        const year = monthDate.getFullYear();

        categoriesList.push([monthName, year.toString()]);
      }

      const seriesList = [];
      const categoryCount = categoriesList.length;

      for (const item of this.OGFakeActivityData) {
        const date = new Date(item.date.split(' at')[0]);
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        const index = categoriesList.findIndex(
          category => category[0] === month && category[1] === year.toString()
        );

        // Determine series grouping
        let seriesName = 'Total';
        let secondaryName = null;
        if (splitBy === 'user') seriesName = item.user_name;
        if (splitBy === 'finClass') seriesName = item.current_fin_class;
        else if (splitBy === 'payerPlan') {
          seriesName = item.current_payer;
          secondaryName = item.current_plan;
        } else if (splitBy === 'activityTypeCode') {
          seriesName = item.activity_type;
          secondaryName = item.activity_code;
        }

        let seriesItem = this.getOrCreateSeries(
          seriesList,
          seriesName,
          secondaryName,
          categoryCount
        );
        seriesItem.data[index] = (seriesItem.data[index] ?? 0) + 1;
      }

      let updatedSeriesList = seriesList;

      if (seriesList.length > 5) {
        updatedSeriesList = this.condenseSeriesList(seriesList);
      }

      let sortSeries = seriesList;

      if (columnId) {
        if (columnId == 'name') {
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.name;
            const valueB = b.name;
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else if (columnId == 'secondName') {
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.secondName;
            const valueB = b.secondName;
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else if (columnId == 'total') {
          //Sort by Total
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const valueB = b.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else {
          //Everything else
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data[columnId];
            const valueB = b.data[columnId];
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        }
      }
      const totalData = sortSeries.length;
      sortSeries = sortSeries.slice(
        (pageNumber - 1) * pageLimit,
        pageNumber * pageLimit
      );

      const seriesListCopy = [...sortSeries];
      seriesListCopy.push(this.createTotalSeries(seriesList));

      return {
        series: splitBy == 'none' ? seriesList : updatedSeriesList,
        categories: categoriesList,
        sortableSeries: seriesListCopy,
        totalData: totalData,
      };
    },
    condenseSeriesList(seriesList) {
      // Map seriesList to get totals and data
      const pleaseWork = seriesList.map(series => {
        const total = series.data.reduce((sum, a) => sum + a, 0);
        return {
          total,
          name: series.secondName
            ? `${series.name}/${series.secondName}`
            : series.name,
          data: series.data, // Keep the data as well
        };
      });

      pleaseWork.sort((a, b) => b.total - a.total);

      // Get top 5 highest totals
      const top5 = pleaseWork.slice(0, 5);

      const others = pleaseWork.slice(5).reduce(
        (acc, series) => {
          acc.total += series.total;
          series.data.forEach((value, index) => {
            acc.data[index] = (acc.data[index] || 0) + value;
          });
          return acc;
        },
        { name: 'Other', total: 0, data: [] }
      );

      return [...top5, others];
    },

    createTotalSeries(seriesList) {
      if (seriesList.length === 0) return { name: 'Total', data: [] };

      const maxLength = Math.max(
        ...seriesList.map(series => series.data.length)
      );

      const totalData = new Array(maxLength).fill(0);

      seriesList.forEach(series => {
        series.data.forEach((value, index) => {
          totalData[index] += value;
        });
      });

      return { name: 'Total', data: totalData };
    },

    getOrCreateSeries(seriesList, seriesName, secondaryName, categoryLength) {
      let seriesItem = seriesList.find(s => s.name === seriesName);
      if (secondaryName) {
        seriesItem = seriesList.find(
          s => s.name === seriesName && s.secondName === secondaryName
        );
      }
      if (!seriesItem) {
        if (secondaryName) {
          seriesItem = {
            name: seriesName,
            secondName: secondaryName,
            data: new Array(categoryLength).fill(0),
          };
        } else {
          seriesItem = {
            name: seriesName,
            data: new Array(categoryLength).fill(0),
          };
        }
        seriesList.push(seriesItem);
      }

      return seriesItem;
    },
  },
});
