<template>
  <PageView>
    <div class="flex flex-col w-full text-sm">
      <div class="min-h-[50px] h-fit">
        <p class="text-2xl text-primary font-bold">User Management</p>
      </div>
      <div
        class="admin-table overflow-auto drop-shadow-md max-h-[75vh] min-h-[25vh]"
      >
        <AdminPanelTable />
      </div>
    </div>
    <ModalComponentV2 modal_id="create-user-modal">
      <UserModal />
    </ModalComponentV2>
  </PageView>
</template>

<script setup>
import { ref, provide, onMounted } from 'vue';
import { Modal } from 'flowbite';
import PageView from '@/components/PageVue.vue';
import ModalComponentV2 from '@/components/ModalComponentFlowBite.vue';
import UserModal from '@/components/UserModal.vue';
import AdminPanelTable from './AdminPanelTable.vue';

const modalEl = ref(null);

onMounted(() => {
  const options = {
    backdrop: 'static',
  };

  const instanceOptions = {
    id: 'create-user-modal',
    override: true,
  };

  modalEl.value = new Modal(
    document.getElementById('create-user-modal'),
    options,
    instanceOptions
  );

  const blurInputs = async () => {
    const userModal = document.getElementById('create-user-modal');
    if (userModal) {
      userModal.querySelectorAll('*').forEach(child => {
        child.blur();
      });
    }
  };

  modalEl.value._options.onHide = blurInputs;
});

provide('userModalControls', modalEl);
</script>

<style scoped></style>
