<template>
  <PageView>
    <StatsTopNavBar
      modal-title="Activity - Advanced Filters"
      :tab-items="navBarTabs"
      :selected-tab="activeTab"
      :filters-applied="activityStatsStore.filtersApplied"
    >
      <template #modal-content>
        <ActivityFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between mb-4 w-full">
        <h1 class="text-rev-2xl text-primary font-bold">
          Activity {{ activeTab }}
        </h1>
        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
            <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
          </div>
          <div class="flex items-center gap-1 justify-end">
            <span class="text-rev-sm text-gray-500 font-bold"
              >Last Updated:</span
            >
            <span class="text-rev-sm text-gray-400">{{
              activityStatsStore.lastUpdated
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col-reverse bg-white p-4 rounded-lg max-w-full overflow-x-scroll relative"
        :style="{ transform: 'rotateX(180deg)' }"
      >
        <div class="w-fit" :style="{ transform: 'rotateX(180deg)' }">
          <table
            class="text-sm text-left text-gray-500 ![--tw-drop-shadow:none]"
          >
            <thead class="text-sm bg-white">
              <tr>
                <template
                  v-for="(header, index) in activityDetailHeaders"
                  :key="index"
                >
                  <TableHeader
                    :id="header.key"
                    :title="header.value"
                    :show-sort="header.sort"
                    :style="header.style"
                    class="px-4 py-3 bg-gray-100 w-80"
                    @sort="sortFields"
                  />
                </template>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(item, index) in activityStatsStore.fakeActivityData"
                :key="index"
              >
                <tr
                  :data-index="index"
                  class="border-b hover:bg-secondary-hover cursor-pointer"
                  @dblclick="
                    async () => {
                      //Ideally the data will have a work item id we can link to
                      //This is just a temporary measure while all the data is fake
                      const assignmentsStore = useAssignmentsStore();
                      await assignmentsStore.fetchUserLead();
                      const workItemList = assignmentsStore.readyAssignments;
                      if (!workItemList) {
                        console.warn('Please assign yourself some work items');
                      } else {
                        router.push({
                          path: `/lead/${workItemList[0].work_item_id}`,
                        });
                      }
                    }
                  "
                >
                  <td
                    v-for="(header, tableDataIndex) in activityDetailHeaders"
                    :key="tableDataIndex"
                    class="ml-1 px-4"
                  >
                    <StatsTableCell
                      :item="item"
                      :header="header"
                      :width="header.width"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="flex pt-4 justify-between items-center sticky left-0"
          :style="{ transform: 'rotateX(180deg)' }"
        >
          <StatsPagination
            :records="activityStatsStore.totalRows"
            :limit="Number(activityStatsStore.searchLimit)"
            :page="activityStatsStore.searchPage"
            @paginate="paginate"
          />
        </div>
      </div>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import { useActivityStatsStore } from '@/stores/stats/useActivityStats';
import ActivityFilterModal from '@/components/stats/modals/ActivityFilterModal.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import StatsTableCell from '@/components/stats/table/StatsTableCell.vue';
import StatsPagination from '@/components/stats/table/StatsPagination.vue';
import { onMounted } from 'vue';
import router from '@/router';
import { useAssignmentsStore } from '@/stores/useAssignments';

const activityStatsStore = useActivityStatsStore();

const activeTab = 'Detail';
const dateRange = `${activityStatsStore.startDate} - ${activityStatsStore.endDate}`;

const activityDetailHeaders = [
  { key: 'date', value: 'Date', width: '8rem', sort: true },
  { key: 'time', value: 'Time', width: '8rem', sort: true },
  { key: 'user_name', value: 'User Name', width: '8rem', sort: true },
  { key: 'activity_type', value: 'Activity Type', width: '8rem', sort: true },
  { key: 'activity_code', value: 'Activity Code', width: '8rem', sort: true },
  { key: 'account_number', value: 'Acct Number', width: '8rem', sort: true },
  { key: 'visit_number', value: 'Visit Number', width: '8rem', sort: true },
  {
    key: 'current_balance',
    value: 'Current Balance',
    width: '8rem',
    sort: true,
  },
  { key: 'phase', value: 'Phase', width: '8rem', sort: true },
  { key: 'status', value: 'Status', width: '8rem', sort: true },
  { key: 'reason', value: 'Reason', width: '8rem', sort: true },
  { key: 'score', value: 'Score', width: '4rem', sort: true },
  {
    key: 'current_fin_class',
    value: 'Current Fin Class',
    width: '8rem',
    sort: true,
  },
  { key: 'current_payer', value: 'Current Payer', width: '8rem', sort: true },
  { key: 'current_plan', value: 'Current Plan', width: '8rem', sort: true },
];

const sortFields = (order = 'asc', field) => {
  activityStatsStore.fetchAndSortActivityStatsData(1, order, field);
};

function paginate(page) {
  activityStatsStore.fetchAndSortActivityStatsData(
    page,
    activityStatsStore.searchDirection,
    activityStatsStore.searchField
  );
}

onMounted(() => {
  paginate(activityStatsStore.searchPage);
});
</script>
<style scoped></style>
