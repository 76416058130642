<template>
  <PageView>
    <StatsTopNavBar
      modal-title="AR - Advanced Filters"
      :tab-items="navBarTabs"
      :selected-tab="activeTab"
      :filters-applied="arStatsStore.filtersApplied"
    >
      <template #modal-content>
        <ARFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between mb-4 w-full">
        <h1 class="text-rev-2xl text-primary font-bold">AR {{ activeTab }}</h1>
        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
            <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
          </div>
          <div class="flex items-center gap-1 justify-end">
            <span class="text-rev-sm text-gray-500 font-bold"
              >Last Updated:</span
            >
            <span class="text-rev-sm text-gray-400">{{
              arStatsStore.lastUpdated
            }}</span>
          </div>
        </div>
      </div>
      <!-- There is some black magic going on here to get the scrollbar at the top of the table -->
      <div
        class="flex flex-col-reverse bg-white p-4 rounded-lg max-w-full overflow-x-scroll relative"
        :style="{ transform: 'rotateX(180deg)' }"
      >
        <div class="w-fit" :style="{ transform: 'rotateX(180deg)' }">
          <table
            class="text-sm text-left text-gray-500 ![--tw-drop-shadow:none]"
          >
            <thead class="text-sm bg-white">
              <tr>
                <template
                  v-for="(header, index) in arDetailHeaders"
                  :key="index"
                >
                  <TableHeader
                    :id="header.key"
                    :title="header.value"
                    :show-sort="header.sort"
                    :style="header.style"
                    class="px-4 py-3 bg-gray-100 w-80"
                    :showArrows="true"
                    @sort="sortFields"
                  />
                </template>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(item, index) in arStatsStore.fakeARData"
                :key="index"
              >
                <tr
                  :data-index="index"
                  class="border-b hover:bg-secondary-hover cursor-pointer"
                  @dblclick="
                    async () => {
                      //Ideally the data will have a work item id we can link to
                      //This is just a temporary measure while all the data is fake
                      const assignmentsStore = useAssignmentsStore();
                      await assignmentsStore.fetchUserLead();
                      const workItemList = assignmentsStore.readyAssignments;
                      if (!workItemList) {
                        console.warn('Please assign yourself some work items');
                      } else {
                        router.push({
                          path: `/lead/${workItemList[0].work_item_id}`,
                        });
                      }
                    }
                  "
                >
                  <td
                    v-for="(header, tableDataIndex) in arDetailHeaders"
                    :key="tableDataIndex"
                    class="ml-1 px-4"
                  >
                    <StatsTableCell
                      :item="item"
                      :header="header"
                      :width="header.width"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="flex pt-4 justify-between items-center sticky left-0"
          :style="{ transform: 'rotateX(180deg)' }"
        >
          <StatsPagination
            :records="arStatsStore.totalRows"
            :limit="Number(arStatsStore.searchLimit)"
            :page="arStatsStore.searchPage"
            @paginate="paginate"
          />
        </div>
      </div>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import { useArStatsStore } from '@/stores/stats/useARStats';
import ARFilterModal from '@/components/stats/modals/ARFilterModal.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import StatsTableCell from '@/components/stats/table/StatsTableCell.vue';
import StatsPagination from '@/components/stats/table/StatsPagination.vue';
import { onMounted } from 'vue';
import { useAssignmentsStore } from '@/stores/useAssignments';
import router from '@/router';
import { arDetailHeaders } from './constants';

const arStatsStore = useArStatsStore();

const activeTab = 'Detail';
const dateRange = `${arStatsStore.startDate} - ${arStatsStore.endDate}`;

const sortFields = (order = 'asc', field) => {
  arStatsStore.fetchAndSortARStatsData(1, order, field);
};

function paginate(page) {
  arStatsStore.fetchAndSortARStatsData(
    page,
    arStatsStore.searchDirection,
    arStatsStore.searchField
  );
}

onMounted(() => {
  paginate(arStatsStore.searchPage);
});
</script>
<style scoped></style>
