<template>
  <div class="flex flex-col gap-3">
    <div class="flex gap-3">
      <StatsDatePicker
        ref="startDatePicker"
        label="Start Date"
        uniqueIdString="activityStartDate"
        placeholder="MM/DD/YYYY"
        :model-value="startDate"
        :maxDate="endDate"
        @update="
          {
            newDate => {
              startDate = newDate;
            };
          }
        "
      />
      <StatsDatePicker
        ref="endDatePicker"
        label="End Date"
        uniqueIdString="activityEndDate"
        placeholder="MM/DD/YYYY"
        :model-value="endDate"
        :minDate="startDate"
        @update="
          {
            newDate => {
              endDate = newDate;
            };
          }
        "
      />
    </div>
    <div class="flex flex-col gap-3">
      <span class="font-bold p-0.5 text-gray-900 text-sm">Billing Type</span>
      <RadioButtons
        ref="billingTypeRef"
        :button-options="radioButtonOptions"
        @select="
          {
            selection => {
              billingTypeSelection = radioButtonOptions[selection];
            };
          }
        "
      />
    </div>
    <div class="flex flex-col gap-3">
      <span class="font-bold p-0.5 text-gray-900 text-sm"
        >Additional Criteria</span
      >
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="billingProviderDropdownRef"
          unique-id-string="billing-provider-dropdown"
          variant="secondary"
          text-key="billingProvider"
          :options="arStatsStore.billingProviderOptions"
          button-text="Billing Provider"
          @update-selection="
            selection => {
              billingProviderSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="billingEntityDropdownRef"
          unique-id-string="ar-billing-entity-dropdown"
          variant="secondary"
          text-key="billingEntity"
          :options="arStatsStore.billingEntityOptions"
          button-text="Billing Entity"
          @update-selection="
            selection => {
              billingEntitySelection = selection;
            }
          "
        />
      </div>
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="userDropdownRef"
          unique-id-string="ar-user-dropdown"
          variant="secondary"
          text-key="user"
          :options="arStatsStore.userDropdownOptions"
          button-text="User"
          @update-selection="
            selection => {
              userSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="locationDropdownRef"
          unique-id-string="location-dropdown"
          variant="secondary"
          text-key="location"
          :options="arStatsStore.locationOptions"
          button-text="Location"
          @update-selection="
            selection => {
              locationSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="departmentDropdownRef"
          unique-id-string="department-dropdown"
          variant="secondary"
          text-key="department"
          :options="arStatsStore.departmentOptions"
          button-text="Department"
          @update-selection="
            selection => {
              departmentSelection = selection;
            }
          "
        />
      </div>
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="currentFinClassDropdownRef"
          unique-id-string="ar-current-fin-dropdown"
          variant="secondary"
          text-key="financialClass"
          :options="arStatsStore.financialClassOptions"
          button-text="Current Financial Class"
          @update-selection="
            selection => {
              currentFinClassSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="currentPayerPlanDropdownRef"
          unique-id-string="ar-payer-plan-dropdown"
          variant="secondary"
          text-key="payerPlan"
          :options="arStatsStore.payerPlanDDOptions"
          button-text="Current Payer/Plan"
          @update-selection="
            selection => {
              currentPayerPlanSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="phaseStatusReasonDropdownRef"
          unique-id-string="ar-phase-status-reason-dropdown"
          variant="secondary"
          text-key="phaseStatusReason"
          :options="arStatsStore.phaseStatusReasonOptions"
          button-text="Phase/Status/Reason"
          @update-selection="
            selection => {
              phaseStatusReasonSelection = selection;
            }
          "
        />
      </div>
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="balanceBucketDropdownRef"
          unique-id-string="balance-bucket-dropdown"
          variant="secondary"
          text-key="balanceBucket"
          :options="arStatsStore.balanceBucketOptions"
          button-text="Balance Bucket"
          @update-selection="
            selection => {
              balanceBucketSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="ageCategoryDropdownRef"
          unique-id-string="age-category-dropdown"
          variant="secondary"
          text-key="ageCategory"
          :options="arStatsStore.ageCategoryOptions"
          button-text="Age Category"
          @update-selection="
            selection => {
              ageCategorySelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="lastActivityDropdownRef"
          unique-id-string="last-activity-dropdown"
          variant="secondary"
          text-key="lastActivityType"
          :options="arStatsStore.lastActivityTypeCodeOptions"
          button-text="Activity Type/Code"
          @update-selection="
            selection => {
              lastActivitySelection = selection;
            }
          "
        />
      </div>
    </div>
  </div>
  <hr class="h-px my-10 bg-gray-200 border-0 dark:bg-gray-700" />
  <div class="flex flex-row w-1/4 pr-4 self-end gap-2">
    <OutlinedButtonFlowBite
      class="text-gray-900 font-bold"
      @click="resetFilters"
    >
      Reset
    </OutlinedButtonFlowBite>
    <SolidButtonFlowBite
      variant="tertiary"
      class="!font-bold"
      @click="applyFilters"
    >
      Apply
    </SolidButtonFlowBite>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '@/utils/ogFakeData';
import SolidButtonFlowBite from '@/components/buttons/SolidButtonFlowBite.vue';
import StatsMultiSelectFlowbite from '../StatsMultiSelectFlowbite.vue';
import StatsDatePicker from '../StatsDatePicker.vue';
import OutlinedButtonFlowBite from '@/components/buttons/OutlinedButtonFlowBite.vue';
import RadioButtons from '../RadioButtons.vue';
import { useArStatsStore } from '@/stores/stats/useARStats';

const arStatsStore = useArStatsStore();

const radioButtonOptions = ['Show All', 'Hospital', 'Physician'];

const billingTypeSelection = ref(null);
const billingProviderSelection = ref(null);
const billingEntitySelection = ref([]);
const userSelection = ref([]);
const locationSelection = ref([]);
const departmentSelection = ref([]);
const currentFinClassSelection = ref([]);
const currentPayerPlanSelection = ref([]);
const phaseStatusReasonSelection = ref([]);
const balanceBucketSelection = ref([]);
const ageCategorySelection = ref([]);
const lastActivitySelection = ref([]);

const startDate = ref(arStatsStore.startDate);
const endDate = ref(arStatsStore.endDate);

const billingTypeRef = ref(null);
const billingProviderDropdownRef = ref(null);
const billingEntityDropdownRef = ref(null);
const userDropdownRef = ref(null);
const locationDropdownRef = ref(null);
const departmentDropdownRef = ref(null);
const currentFinClassDropdownRef = ref(null);
const currentPayerPlanDropdownRef = ref(null);
const phaseStatusReasonDropdownRef = ref(null);
const balanceBucketDropdownRef = ref(null);
const ageCategoryDropdownRef = ref(null);
const lastActivityDropdownRef = ref(null);

const startDatePicker = ref(null);
const endDatePicker = ref(null);

const filterRefs = {
  billingType: billingTypeRef,
  billingProvider: billingProviderDropdownRef,
  billingEntity: billingEntityDropdownRef,
  user: userDropdownRef,
  location: locationDropdownRef,
  department: departmentDropdownRef,
  currentFinClass: currentFinClassDropdownRef,
  currentPayerPlan: currentPayerPlanDropdownRef,
  phaseStatusReason: phaseStatusReasonDropdownRef,
  balanceBucket: balanceBucketDropdownRef,
  ageCategory: ageCategoryDropdownRef,
  lastActivity: lastActivityDropdownRef,
};

const filterSelections = {
  billingProvider: 'selectedBillingProvider',
  billingEntity: 'selectedBillingEntity',
  user: 'selectedUser',
  location: 'selectedLocation',
  department: 'selectedDepartment',
  currentFinClass: 'selectedCurrentFinClass',
  currentPayerPlan: 'selectedCurrentPayerPlan',
  phaseStatusReason: 'selectedPhaseStatusReason',
  balanceBucket: 'selectedBalanceBucket',
  ageCategory: 'selectedAgeCategory',
  lastActivity: 'selectedLastActivity',
};

const resetFilters = () => {
  Object.values(filterRefs).forEach(ref => {
    ref.value.clearSelection();
  });

  startDatePicker.value.clearSelection(DEFAULT_START_DATE);
  endDatePicker.value.clearSelection(DEFAULT_END_DATE);
  arStatsStore.resetFilters();
};

const applyFilters = () => {
  Object.entries(filterSelections).forEach(([key, storeKey]) => {
    arStatsStore[storeKey] = filterSelections[key].value;
  });

  arStatsStore.startDate = startDate.value;
  arStatsStore.endDate = endDate.value;
  arStatsStore.applyFilters();
};
</script>
