<template>
  <ul
    class="flex items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg"
  >
    <li
      v-for="(button, index) in buttonOptions"
      :key="index"
      class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r"
    >
      <div class="flex items-center ps-3">
        <input
          :id="button"
          type="radio"
          :checked="index == selectedButton"
          name="list-radio"
          class="w-4 h-4 accent-primary cursor-pointer"
          @change="handleChangeButton(index)"
        />
        <label
          :for="button"
          class="w-full py-3 ms-2 text-sm font-bold text-gray-900 cursor-pointer"
          >{{ button }}
        </label>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { ref } from 'vue';
const props = defineProps({
  buttonOptions: {
    default: () => [],
    type: Array,
  },
});

const selectedButton = ref(0);

const handleChangeButton = index => {
  selectedButton.value = index;
  emit('select', index);
};

const emit = defineEmits(['select']);

const clearSelection = () => {
  selectedButton.value = 0;
  emit('select', 0);
};
defineExpose({ clearSelection });
</script>
