<template>
  <button
    id="dropdownRadioButton"
    class="w-16 flex items-center"
    type="button"
    @click="showDropdown"
  >
    {{ buttonText }}
    <svg
      class="w-2.5 h-2.5 ms-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 4 4 4-4"
      />
    </svg>
  </button>

  <!-- Dropdown menu -->
  <div
    id="dropdownRadio"
    class="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow-sm dark:bg-gray-700 dark:divide-gray-600"
  >
    <ul
      class="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
      aria-labelledby="dropdownRadioButton"
    >
      <li v-for="option in dropdownOptions" :key="option.value">
        <div class="flex items-center">
          <input
            :id="'radio-' + option.value"
            type="radio"
            :value="option.value"
            :checked="selectedButton === option.value"
            class="w-4 h-4 accent-primary"
            @change="handleRadioChange(option.value)"
          />
          <label
            :for="'radio-' + option.value"
            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {{ option.text }}
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { Dropdown } from 'flowbite';
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  buttonText: {
    type: String,
    default: 'Select',
  },
  dropdownOptions: {
    type: Array,
    default: () => [],
  },
  defaultSelected: {
    type: String,
    default: '',
  },
});

const dropdown = ref(null);
const selectedButton = ref(props.defaultSelected);

watch(
  () => props.defaultSelected,
  newVal => {
    selectedButton.value = newVal;
  },
  { immediate: true }
);

onMounted(() => {
  const $targetEl = document.getElementById('dropdownRadio');
  const $triggerEl = document.getElementById('dropdownRadioButton');

  const options = {
    triggerType: 'click',
    placement: 'bottom',
    offsetSkidding: 0,
    offsetDistance: 10,
  };

  dropdown.value = new Dropdown($targetEl, $triggerEl, options);
});

const showDropdown = () => {
  dropdown.value.show();
};

const handleRadioChange = option => {
  selectedButton.value = option;
  emit('change', option);
  dropdown.value.hide();
};

const emit = defineEmits(['change']);
</script>
