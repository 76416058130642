<template>
  <PageView page-classes="!overflow-x-scroll" body-classes="min-w-[800px]">
    <StatsTopNavBar
      modal-title="Work Gap - Advanced Filters"
      :tab-items="navBarTabs"
      :selected-tab="activeTab"
      :filters-applied="workGapStore.filtersApplied"
      :clear-filter-callback="handleClearFilter"
    >
      <template #modal-content="{ closeModal }">
        <WorkGapFilterModal :close-modal="closeModal" />
      </template>
    </StatsTopNavBar>
    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between mb-4 w-full">
        <h1 class="text-rev-2xl text-primary font-bold">
          Work Gap {{ activeTab }}
        </h1>
        <div class="flex items-center gap-1">
          <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
          <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
        </div>
      </div>
      <div class="flex flex-row gap-4 w-full">
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-classes">
            {{ workGapStore.currentMonth }} Total
          </span>
          <StatsValue :value="workGapStore.monthlyTotal" />
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-classes">
            {{ workGapStore.sixMonthRange }} Average
          </span>
          <StatsValue :value="workGapStore.sixMonthAvg"> </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-classes">
            {{ workGapStore.lastMonth }} vs 6 Month Average
          </span>
          <StatsValue
            :value="workGapStore.monthlyVS6MonthAverageCount.value"
            :caret-ternary="
              workGapStore.monthlyVS6MonthAverageCount.caretTernary ?? 0
            "
            :color-ternary="
              workGapStore.monthlyVS6MonthAverageCount.colorTernary ?? 0
            "
          >
          </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-classes">
            {{ workGapStore.lastMonth }} vs 6 Month Average
          </span>
          <StatsValue
            :value="workGapStore.monthlyVS6MonthAveragePercent.value"
            :caret-ternary="
              workGapStore.monthlyVS6MonthAveragePercent.caretTernary ?? 0
            "
            :color-ternary="
              workGapStore.monthlyVS6MonthAveragePercent.colorTernary ?? 0
            "
            :percentage="true"
          >
          </StatsValue>
        </StatsCard>
      </div>
      <div class="flex flex-row gap-4 justify-between">
        <StatsGroupCard size-classes="w-1/2" group-title="Monthly Insights">
          <template
            v-for="(statEntry, index) in workGapStore.monthlyInsights"
            :key="index"
          >
            <StatsCard
              container-classes="justify-center items-center rounded-md"
              color-classes="bg-gray-75"
              size-classes="w-1/3 h-24"
              :show-warning="statEntry.showWarning"
            >
              <StatsValue
                :value="statEntry.value"
                :caret-ternary="statEntry.caretTernary ?? 0"
                :color-ternary="statEntry.colorTernary ?? 0"
              >
              </StatsValue>
              <div class="text-classes">
                {{ statEntry.labelText }}
              </div>
              <div class="font-bold text-sm text-center">
                {{ statEntry.textValue }}
              </div>
            </StatsCard>
          </template>
        </StatsGroupCard>
        <StatsGroupCard size-classes="w-1/2" group-title="High Occurrence Days">
          <template
            v-for="(statEntry, index) in workGapStore.highOccurrenceDays"
            :key="index"
          >
            <StatsCard
              container-classes="justify-center items-center rounded-md"
              color-classes="bg-gray-75"
              size-classes="w-1/3 h-24"
            >
              <StatsValue :value="statEntry.value"> </StatsValue>
              <div class="text-classes">
                {{ statEntry.textValue }}
              </div>
            </StatsCard>
          </template>
        </StatsGroupCard>
      </div>
      <StatsGroupCard
        size-classes="w-full"
        group-title="Users With The Most Occurrences"
      >
        <WorkGapOverviewTable
          :col-headers="WORK_GAP_HEADERS"
          :table-data="workGapStore.mostOccurrenceUsers.slice(0, 5)"
        />
        <WorkGapOverviewTable
          :col-headers="WORK_GAP_HEADERS"
          :table-data="workGapStore.mostOccurrenceUsers.slice(5)"
          :start-index="6"
        />
      </StatsGroupCard>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import StatsCard from '@/components/stats/Cards/StatsCard.vue';
import StatsGroupCard from '@/components/stats/Cards/StatsGroupCard.vue';
import StatsValue from '@/components/stats/Misc/StatsValue.vue';
import { useWorkGapStore } from '@/stores/stats/useWorkGapStats';
import WorkGapFilterModal from '@/components/stats/modals/WorkGapFilterModal.vue';
import { computed, onMounted } from 'vue';
import WorkGapOverviewTable from '@/components/stats/table/WorkGapOverviewTable.vue';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '@/utils/ogFakeData';

const workGapStore = useWorkGapStore();

const WORK_GAP_HEADERS = [
  { key: 'user', value: 'User' },
  { key: 'smallhours', value: '1.5 - 3 hours' },
  { key: 'largehours', value: '3+ hours' },
  { key: 'total', value: 'Total' },
];

const dateRange = computed(
  () => `${workGapStore.startDate} - ${workGapStore.endDate}`
);
const activeTab = 'Overview';

onMounted(async () => {
  await workGapStore.fetchMetrics();
});

const handleClearFilter = async filter => {
  // Keep current values for filters we're not changing
  const currentStartDate = workGapStore.startDate;
  const currentEndDate = workGapStore.endDate;
  const currentUsers = workGapStore.selectedUsers;
  const currentActivityGap = workGapStore.selectedActivityGap;

  // Update only the filter being cleared
  if (filter == 'Date Range Applied') {
    workGapStore.applyFilters(
      workGapStore.defaultStartDate,
      workGapStore.defaultEndDate,
      currentUsers,
      currentActivityGap
    );
  } else if (filter === 'Users') {
    workGapStore.applyFilters(
      currentStartDate,
      currentEndDate,
      [], // Clear users
      currentActivityGap
    );
  } else if (filter === 'Activity Gap') {
    workGapStore.applyFilters(
      currentStartDate,
      currentEndDate,
      currentUsers,
      0 // Clear activity gap
    );
  }

  // Refetch with updated filters
  await workGapStore.fetchMetrics();
};
</script>
<style scoped>
.text-classes {
  @apply text-sm text-gray-500 font-light text-center;
}
</style>
