import { defineStore } from 'pinia';
import {
  PAYER_PLAN_OPTIONS,
  PHASE_STATUS_REASON_OPTIONS,
  BILLING_ENTITY_OPTIONS,
  FINANCIAL_CLASS_OPTIONS,
  BILLING_PROVIDER_OPTIONS,
  USER_OPTIONS,
  LOCATION_OPTIONS,
  DEPARTMENT_OPTIONS,
  BALANCE_BUCKET_OPTIONS,
  AGE_CATEGORY_OPTIONS,
  LAST_ACTIVITY_TYPE_OPTIONS,
  FAKE_AR_DATA,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
  FAKE_AR_BREAKOUT_DATA,
  fetchAndSortARData,
  addStatusesToHighDollar,
} from '@/utils/ogFakeData';

export const useArStatsStore = defineStore('arStats', {
  state: () => ({
    searchLimit: 10,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'rsn',
    totalRows: 0,

    fakeARData: [],
    fakeHighDollarData: [],
    OGFakeActivityData: FAKE_AR_DATA,

    startDate: '04/01/2024',
    endDate: '10/31/2024',
    lastUpdated: '10/31/2024',

    currentARCount: 1218,
    currentARBalance: 610796,
    percentGreaterThan90: 100,
    percentGreaterThan180: 100,
    percentGreaterThan365: 93,

    highDollarCount: 25,
    highDollarAmount: 9166967,
    highDollarPercentage: 4,

    currentMonthTrendTotal: 168886578,
    dateRangeAverage: 652294123,
    monthVsSixMonthAverage: 5221400,
    percentDiffCurrency: 12.2,
    monthVsSixMonthAverageAgain: 2168,
    percentDiff: 1.4,

    highDollarOverview: [
      {
        name: 'Hospital High Dollar',
        value: 50000,
        type: 'currency',
      },
      {
        name: 'Physician High Dollar',
        value: 30000,
        type: 'currency',
      },
      {
        name: 'Number Of Days From Worked',
        value: 30,
      },
      {
        name: 'Number Of Days From Service',
        value: 30,
      },
      {
        name: 'Number Of Days From Submission',
        value: 30,
      },
    ],

    highDollarCards: [
      {
        cardTitle: 'Total',
        cardColorTernary: 0,
        displayedValues: { count: 1218, balance: 31771773, percentage: 45.3 },
      },
      {
        cardTitle: 'Recently Billed',
        cardColorTernary: 1,
        displayedValues: {
          count: 1218,
          balance: 31771773,
          percentage: 45.6,
        },
      },
      {
        cardTitle: 'Recently Worked',
        cardColorTernary: 2,
        displayedValues: { count: 844, balance: 22604806, percentage: 32.1 },
      },
      {
        cardTitle: 'Needs Attention',
        cardColorTernary: 3,
        displayedValues: { count: 374, balance: 9166967, percentage: 13 },
      },
    ],

    //For modal
    filtersApplied: [],

    billingProviderOptions: BILLING_PROVIDER_OPTIONS,
    billingEntityOptions: BILLING_ENTITY_OPTIONS,
    userDropdownOptions: USER_OPTIONS,
    locationOptions: LOCATION_OPTIONS,
    departmentOptions: DEPARTMENT_OPTIONS,
    financialClassOptions: FINANCIAL_CLASS_OPTIONS,
    payerPlanDDOptions: PAYER_PLAN_OPTIONS,
    phaseStatusReasonOptions: PHASE_STATUS_REASON_OPTIONS,
    balanceBucketOptions: BALANCE_BUCKET_OPTIONS,
    ageCategoryOptions: AGE_CATEGORY_OPTIONS,
    lastActivityTypeCodeOptions: LAST_ACTIVITY_TYPE_OPTIONS,

    //high dollar thresholds
    hospitalHighDollarThreshold: 50000,
    physicianHighDollarThreshold: 3000,
    numberOfDaysFromWorkedThreshold: 30,
    numberOfDaysFromServiceThreshold: 30,
    numberOfDaysFromSubmissionThreshold: 30,
  }),
  getters: {
    getFiltersApplied: state => {
      return state.filtersApplied;
    },
  },
  actions: {
    applyFilters() {
      //There will be more here but this will do for now
      this.filtersApplied = [{ name: 'Date Range Applied' }];
    },
    resetFilters() {
      this.startDate = DEFAULT_START_DATE;
      this.endDate = DEFAULT_END_DATE;
      this.filtersApplied = [];
    },
    async fetchAndSortARStatsData(page = 1, direction = 'asc', field = 'user') {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };

      try {
        const response = await fetchAndSortARData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeARData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
    createSeriesAndCategoriesForChart(
      splitBy = null,
      pageLimit = 5,
      pageNumber = 1,
      columnId = null,
      direction = null,
      totalKey = null
    ) {
      const currentDate = new Date();
      const sixMonthsAgo = new Date(
        currentDate.setMonth(currentDate.getMonth() - 6)
      );
      const categoriesList = [];

      for (let i = 0; i < 7; i++) {
        const monthDate = new Date(sixMonthsAgo);
        monthDate.setMonth(monthDate.getMonth() + i);

        const monthName = monthDate.toLocaleString('default', {
          month: 'short',
        });
        const year = monthDate.getFullYear();

        categoriesList.push([monthName, year.toString()]);
      }

      const seriesList = [];
      const categoryCount = categoriesList.length;

      for (const item of FAKE_AR_BREAKOUT_DATA) {
        const date = new Date(item.date.split(' at')[0]);
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        const index = categoriesList.findIndex(
          category => category[0] === month && category[1] === year.toString()
        );

        // Determine series grouping
        let seriesName = 'Total';
        let secondaryName = null;
        let tertiaryName = null;
        if (splitBy === 'current_payer') {
          seriesName = item.current_payer;
          secondaryName = item.current_plan;
        } else if (splitBy === 'phase') {
          seriesName = item.phase;
          secondaryName = item.status;
          tertiaryName = item.reason;
        } else if (splitBy === 'activity_type') {
          seriesName = item.activity_type;
          secondaryName = item.activity_code;
        } else if (splitBy) {
          seriesName = item[splitBy];
        }

        let seriesItem = this.getOrCreateSeries(
          seriesList,
          seriesName,
          secondaryName,
          tertiaryName,
          categoryCount
        );
        seriesItem.data[index] =
          (seriesItem.data[index] ?? 0) + (totalKey ? item[totalKey] : 1);
      }

      let updatedSeriesList = seriesList;

      if (seriesList.length > 5) {
        updatedSeriesList = this.condenseSeriesList(seriesList);
      }

      let sortSeries = seriesList;

      if (columnId) {
        if (columnId == 'name') {
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.name;
            const valueB = b.name;
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else if (columnId == 'secondName') {
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.secondName;
            const valueB = b.secondName;
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else if (columnId == 'total') {
          //Sort by Total
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const valueB = b.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else {
          //Everything else
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data[columnId];
            const valueB = b.data[columnId];
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        }
      }
      let totalSize = sortSeries.length;
      sortSeries = sortSeries.slice(
        (pageNumber - 1) * pageLimit,
        pageNumber * pageLimit
      );

      const seriesListCopy = [...sortSeries];
      seriesListCopy.push(this.createTotalSeries(seriesList));

      return {
        series: splitBy == 'none' ? seriesList : updatedSeriesList,
        categories: categoriesList,
        sortableSeries: seriesListCopy,
        seriesLength: totalSize,
      };
    },
    condenseSeriesList(seriesList) {
      // Map seriesList to get totals and data
      const pleaseWork = seriesList.map(series => {
        const total = series.data.reduce((sum, a) => sum + a, 0);
        return {
          total,
          name: series.thirdName
            ? `${series.name}/${series.secondName}/${series.thirdName}`
            : series.secondName
            ? `${series.name}/${series.secondName}`
            : series.name,
          data: series.data, // Keep the data as well
        };
      });

      pleaseWork.sort((a, b) => b.total - a.total);

      // Get top 5 highest totals
      const top5 = pleaseWork.slice(0, 5);

      const others = pleaseWork.slice(5).reduce(
        (acc, series) => {
          acc.total += series.total;
          series.data.forEach((value, index) => {
            acc.data[index] = (acc.data[index] || 0) + value;
          });
          return acc;
        },
        { name: 'Other', total: 0, data: [] }
      );

      return [...top5, others];
    },
    createTotalSeries(seriesList) {
      if (seriesList.length === 0) return { name: 'Total', data: [] };

      const maxLength = Math.max(
        ...seriesList.map(series => series.data.length)
      );

      const totalData = new Array(maxLength).fill(0);

      seriesList.forEach(series => {
        series.data.forEach((value, index) => {
          totalData[index] += value;
        });
      });

      return { name: 'Total', data: totalData };
    },
    getOrCreateSeries(
      seriesList,
      seriesName,
      secondaryName,
      tertiaryName,
      categoryLength
    ) {
      let seriesItem = seriesList.find(s => s.name === seriesName);
      if (secondaryName) {
        seriesItem = seriesList.find(
          s => s.name === seriesName && s.secondName === secondaryName
        );
      }
      if (tertiaryName) {
        seriesItem = seriesList.find(
          s => s.name === seriesName && s.thirdName === tertiaryName
        );
      }
      if (!seriesItem) {
        if (secondaryName) {
          seriesItem = {
            name: seriesName,
            secondName: secondaryName,
            thirdName: tertiaryName,
            data: new Array(categoryLength).fill(0),
          };
        } else {
          seriesItem = {
            name: seriesName,
            data: new Array(categoryLength).fill(0),
          };
        }
        seriesList.push(seriesItem);
      }
      return seriesItem;
    },
    async createSeriesAndCategoriesForHighDollarChart(
      pageLimit = 10,
      pageNumber = 1,
      direction = 'asc',
      series = 'rsn',
      filter = 'Needs Attention',
      category = 'finClass'
    ) {
      const ageCategoryCategories = [
        '0-30 Days',
        '31-60 Days',
        '61-90 Days',
        '91-120 Days',
        '121-150 Days',
        '151-180 Days',
        '181-365 Days',
        '365+ Days',
      ];

      const finClassCategories = [
        'Test One',
        'Test Two',
        'Test Three',
        'Test Four',
        'Test Five',
      ];

      const categoriesList =
        category === 'ageCategory' ? ageCategoryCategories : finClassCategories;

      //hardcode this so they're always in the same order
      const seriesList = [
        {
          name: 'Needs Attention',
          data: new Array(categoriesList.length).fill(0),
        },
        {
          name: 'Recently Worked',
          data: new Array(categoriesList.length).fill(0),
        },
        {
          name: 'Recently Billed',
          data: new Array(categoriesList.length).fill(0),
        },
      ];

      const fakeDataWithStatuses = addStatusesToHighDollar();

      for (const item of fakeDataWithStatuses) {
        // Determine series grouping
        let seriesName = item.status;

        let seriesItem = seriesList.find(s => s.name === seriesName);
        let thisThing =
          category == 'finClass' ? 'currentFinancialClass' : 'ageCategory';
        const index = categoriesList.findIndex(cat => cat === item[thisThing]);
        if (index !== -1) {
          seriesItem.data[index] = (seriesItem.data[index] ?? 0) + 1;
        }
      }

      this.searchPage = pageNumber;
      this.searchLimit = pageLimit;
      this.searchDirection = direction;
      this.searchField = series;

      const reqBody = {
        limit: pageLimit,
        page: pageNumber,
        direction,
        field: series,
        filter,
      };

      try {
        const response = await fetchAndSortARData(
          reqBody,
          fakeDataWithStatuses
        );
        this.totalRows = response.totalRows;
        this.fakeHighDollarData = response.fakeData;
        return {
          categories: categoriesList,
          series: seriesList,
          tableData: response.fakeData,
          totalRows: response.totalRows,
        };
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
  },
});
