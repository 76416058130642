<template>
  <StatsCard
    :container-classes="containerClasses"
    :size-classes="sizeClasses"
    :color-classes="colorClasses"
  >
    <div
      v-if="groupTitle && groupTitle.length > 0"
      class="font-bold text-2xl items-start p-4"
    >
      {{ groupTitle }}
    </div>
    <div
      class="h-full"
      :class="{
        [props.slotContainerClasses]: true,
      }"
    >
      <slot />
    </div>
  </StatsCard>
</template>
<script setup>
import StatsCard from './StatsCard.vue';
const props = defineProps({
  containerClasses: {
    type: String,
    default: 'rounded-md',
  },
  slotContainerClasses: {
    type: String,
    default: 'flex flex-row justify-between items-center gap-4 p-4',
  },
  sizeClasses: {
    type: String,
    default: 'w-[40rem] h-[10rem] p-2',
  },
  colorClasses: {
    type: String,
    default: 'bg-white',
  },
  groupTitle: {
    type: String,
    default: 'Title',
  },
});
</script>
