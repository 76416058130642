import { defineStore } from 'pinia';
import {
  FAKE_WORK_GAP_DATA,
  FAKE_DENIALS_DATA,
  fetchAndSortDenialData,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '@/utils/ogFakeData';

export const useDenialsStore = defineStore('denials', {
  state: () => ({
    searchLimit: 15,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'mrn',
    totalRows: 0,
    fakeDenialsData: [],
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
    lastUpdated: '10/31/2024',
    selectedUser: null,
    selectedActivityGap: null,
    OGFakeWorkGapData: FAKE_WORK_GAP_DATA,
    filtersApplied: [],
    currentMonth: 'October',
    monthlyTotal: {
      value: 205356,
      percentageValue: 4.9,
      colorTernary: 2,
      arrowTernary: 1,
    },
    previousYearAverage: { value: 334321, colorTernary: 0 },
    halfYearAverage: { value: 334321 },
    monthlyVS6MonthAverage: {
      value: -78095,
      percentageValue: 12.4,
      colorTernary: 1,
      arrowTernary: 2,
    },
    financialClassInsights: [
      {
        headers: [
          {
            key: 'top3',
            value: 'Top 3 Increase',
            numbered: true,
            centered: false,
          },
          {
            key: 'month',
            value: 'October 2024',
            centered: false,
          },
          {
            key: 'changeOver6Months',
            value: 'Change vs. 6 Month Average',
            centered: false,
          },
        ],
        data: [
          {
            top3: 'Medicaid',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
          {
            top3: 'Workers Comp',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
          {
            top3: 'Commercial',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
        ],
      },
      {
        headers: [
          {
            key: 'top3',
            value: 'Top 3 Decrease',
            numbered: true,
            centered: false,
          },
          {
            key: 'month',
            value: 'October 2024',
            centered: false,
          },
          {
            key: 'changeOver6Months',
            value: 'Change vs. 6 Month Average',
            centered: false,
          },
        ],
        data: [
          {
            top3: 'Blue Cross',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
          {
            top3: 'Commercial',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
          {
            top3: 'Medicare',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
        ],
      },
    ],
    categoryInsights: [
      {
        headers: [
          {
            key: 'top3',
            value: 'Top 3 Increase',
            numbered: true,
            centered: false,
          },
          {
            key: 'month',
            value: 'October 2024',
            centered: false,
          },
          {
            key: 'changeOver6Months',
            value: 'Change vs. 6 Month Average',
            centered: false,
          },
        ],
        data: [
          {
            top3: 'Medicaid',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
          {
            top3: 'Medicaid',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
          {
            top3: 'Medicaid',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
        ],
      },
      {
        headers: [
          {
            key: 'top3',
            value: 'Top 3 Decrease',
            numbered: true,
            centered: false,
          },
          {
            key: 'month',
            value: 'October 2024',
            centered: false,
          },
          {
            key: 'changeOver6Months',
            value: 'Change vs. 6 Month Average',
            centered: false,
          },
        ],
        data: [
          {
            top3: 'Medicaid',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
          {
            top3: 'Medicaid',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
          {
            top3: 'Medicaid',
            month: { value: 566927, format: 'currency' },
            changeOver6Months: {
              value: 646374,
              format: 'currency',
              additionalPercentage: {
                value: 12.6,
                arrowTernary: 1,
                colorTernary: 1,
              },
            },
          },
        ],
      },
    ],
    isLoading: false,
    //These will be updated when we make a pass to make these modals functional
    billingProviderOptions: [
      { id: 1, billingProvider: 'option 1' },
      { id: 1, billingProvider: 'option 2' },
    ],
    billingEntityOptions: [
      { id: 1, billingEntity: 'option 1' },
      { id: 2, billingEntity: 'option 2' },
    ],
    locationOptions: [
      { id: 1, location: 'option 1' },
      { id: 2, location: 'option 2' },
    ],
    departmentOptions: [
      { id: 1, department: 'option 1' },
      { id: 2, department: 'option 2' },
    ],
    financialClassOptions: [
      { id: 1, financialClass: 'option 1' },
      { id: 2, financialClass: 'option 2' },
    ],
    payerPlanOptions: [
      { id: 1, payerPlan: 'option 1' },
      { id: 2, payerPlan: 'option 2' },
    ],
    denialCategoryCodeOptions: [
      { id: 1, denialCategoryCode: 'option 1' },
      { id: 2, denialCategoryCode: 'option 2' },
    ],
    denialGroupingOptions: [
      { id: 1, denialGrouping: 'option 1' },
      { id: 2, denialGrouping: 'option 2' },
    ],
  }),
  getters: {
    getFiltersApplied: state => {
      return state.filtersApplied;
    },
  },
  actions: {
    async fetchAndSortDenialStatsData(
      page = 1,
      direction = 'asc',
      field = 'mrn'
    ) {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };
      try {
        const response = await fetchAndSortDenialData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeDenialsData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
    createSeriesAndCategoriesForChart(
      splitBy = null,
      pageLimit = 5,
      pageNumber = 1,
      columnId = null,
      direction = null,
      totalKey = null
    ) {
      const currentDate = new Date();
      const sixMonthsAgo = new Date(
        currentDate.setMonth(currentDate.getMonth() - 6)
      );
      const categoriesList = [];

      for (let i = 0; i < 7; i++) {
        const monthDate = new Date(sixMonthsAgo);
        monthDate.setMonth(monthDate.getMonth() + i);

        const monthName = monthDate.toLocaleString('default', {
          month: 'short',
        });
        const year = monthDate.getFullYear();

        categoriesList.push([monthName, year.toString()]);
      }

      const seriesList = [];
      const categoryCount = categoriesList.length;

      for (const item of FAKE_DENIALS_DATA) {
        const date = new Date(item.mrn.split(' at')[0]);
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        const index = categoriesList.findIndex(
          category => category[0] === month && category[1] === year.toString()
        );

        // Determine series grouping
        let seriesName = 'Total';
        let secondaryName = null;
        if (splitBy === 'current_payer') {
          seriesName = item.current_payer;
          secondaryName = item.current_plan;
        } else if (splitBy === 'denial_category') {
          seriesName = item.denial_category;
          secondaryName = item.denial_code;
        } else if (splitBy) {
          seriesName = item[splitBy];
        }

        let seriesItem = this.getOrCreateSeries(
          seriesList,
          seriesName,
          secondaryName,
          categoryCount
        );
        seriesItem.data[index] =
          (seriesItem.data[index] ?? 0) + (totalKey ? item[totalKey] : 1);
      }

      let updatedSeriesList = seriesList;

      if (seriesList.length > 5) {
        updatedSeriesList = this.condenseSeriesList(seriesList);
      }

      let sortSeries = seriesList;

      if (columnId) {
        if (columnId == 'name') {
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.name;
            const valueB = b.name;
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else if (columnId == 'secondName') {
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.secondName;
            const valueB = b.secondName;
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else if (columnId == 'total') {
          //Sort by Total
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const valueB = b.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else {
          //Everything else
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data[columnId];
            const valueB = b.data[columnId];
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        }
      }
      let totalSize = sortSeries.length;
      sortSeries = sortSeries.slice(
        (pageNumber - 1) * pageLimit,
        pageNumber * pageLimit
      );

      const seriesListCopy = [...sortSeries];
      seriesListCopy.push(this.createTotalSeries(seriesList));

      return {
        series: splitBy == 'none' ? seriesList : updatedSeriesList,
        categories: categoriesList,
        sortableSeries: seriesListCopy,
        seriesLength: totalSize,
      };
    },
    condenseSeriesList(seriesList) {
      // Map seriesList to get totals and data
      const pleaseWork = seriesList.map(series => {
        const total = series.data.reduce((sum, a) => sum + a, 0);
        return {
          total,
          name: series.secondName
            ? `${series.name}/${series.secondName}`
            : series.name,
          data: series.data, // Keep the data as well
        };
      });

      pleaseWork.sort((a, b) => b.total - a.total);

      // Get top 5 highest totals
      const top5 = pleaseWork.slice(0, 5);

      const others = pleaseWork.slice(5).reduce(
        (acc, series) => {
          acc.total += series.total;
          series.data.forEach((value, index) => {
            acc.data[index] = (acc.data[index] || 0) + value;
          });
          return acc;
        },
        { name: 'Other', total: 0, data: [] }
      );

      return [...top5, others];
    },
    createTotalSeries(seriesList) {
      if (seriesList.length === 0) return { name: 'Total', data: [] };

      const maxLength = Math.max(
        ...seriesList.map(series => series.data.length)
      );

      const totalData = new Array(maxLength).fill(0);

      seriesList.forEach(series => {
        series.data.forEach((value, index) => {
          totalData[index] += value;
        });
      });

      return { name: 'Total', data: totalData };
    },
    getOrCreateSeries(seriesList, seriesName, secondaryName, categoryLength) {
      let seriesItem = seriesList.find(s => s.name === seriesName);
      if (secondaryName) {
        seriesItem = seriesList.find(
          s => s.name === seriesName && s.secondName === secondaryName
        );
      }
      if (!seriesItem) {
        if (secondaryName) {
          seriesItem = {
            name: seriesName,
            secondName: secondaryName,
            data: new Array(categoryLength).fill(0),
          };
        } else {
          seriesItem = {
            name: seriesName,
            data: new Array(categoryLength).fill(0),
          };
        }
        seriesList.push(seriesItem);
      }
      return seriesItem;
    },
    resetFilters() {
      this.selectedUser = null;
      this.selectedActivityGap = null;
      this.startDate = DEFAULT_START_DATE;
      this.endDate = DEFAULT_END_DATE;
      this.filtersApplied = [];
    },
    applyFilters() {
      //There will be more here but this will do for now
      this.filtersApplied = [{ name: 'Date Range Applied' }];
    },
  },
});
