<template>
  <div class="w-1/2">
    <div
      class="grid grid-cols-5 bg-gray-100 w-full p-2 rounded-t-md text-gray-500 font-semibold border-b border-gray-200"
    >
      <span
        v-for="colHeader in props.colHeaders"
        :key="colHeader.key"
        :class="[colHeader.key == 'user' ? 'px-2 col-span-2' : 'text-center']"
      >
        {{ colHeader.value }}
      </span>
    </div>
    <div
      v-for="(item, index) in paddedTableData"
      :key="index"
      class="grid grid-cols-5 border-b border-gray-200"
    >
      <span class="p-2 px-6 border-r col-span-2 truncate"
        >{{ startIndex + index }}.&nbsp;&nbsp;&nbsp;{{ item.user }}</span
      >
      <span class="flex items-center justify-center border-r">
        {{ item.smallHours }}</span
      >
      <span class="flex items-center justify-center border-r">
        {{ item.largeHours }}</span
      >
      <span class="flex items-center justify-center text-secondary font-bold">
        {{ item.total }}</span
      >
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  startIndex: {
    type: Number,
    default: 1,
  },
  colHeaders: {
    type: Array,
    default: () => [],
  },
});

// Create a computed property that ensures we always have 10 rows
const paddedTableData = computed(() => {
  const data = [...props.tableData];
  let rowsNeeded = 5 - data.length;
  if (rowsNeeded > 0) {
    // Add empty rows to fill up to 10
    for (let i = 0; i < rowsNeeded; i++) {
      data.push({
        user: '',
        smallHours: '',
        largeHours: '',
        total: '',
      });
    }
  }

  return data;
});
</script>
