<template>
  <PageView page-classes="!overflow-x-auto" body-classes="min-w-[72rem]">
    <StatsTopNavBar :tab-items="navBarTabs" :selected-tab="activeTab">
      <template #modal-content>
        <DenialFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex flex-col gap-6">
      <div class="flex items-center justify-between mb-4 w-full">
        <div class="flex flex-row items-center justify-center gap-4">
          <h1 class="text-rev-2xl text-primary font-bold">
            Denials {{ activeTab }}
          </h1>
          <div
            class="relative flex flex-col items-center justify-center bg-tertiary rounded-xl text-white w-[1.25rem] h-[1.25rem]"
            @mouseenter="openPopup"
            @mouseleave="hidePopup"
          >
            <div
              v-if="showPopup"
              class="absolute right-[-20rem] z-10 text-sm whitespace-nowrap flex flex-col items-center justify-center bg-white border-2 border-solid border-tertiary rounded-xl text-black w-[19rem] h-[2.5rem]"
            >
              Date Filters cannot be applied to this page.
            </div>
            !
          </div>
        </div>
        <div class="flex items-center gap-1">
          <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
          <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
        </div>
      </div>
      <StatsGroupCard
        size-classes="w-full h-32"
        slotContainerClasses="flex flex-row justify-between items-center gap-4 p-4 px-20"
        group-title=""
      >
        <StatsCard size-classes="w-1/4 h-24">
          <span class="text-sm text-gray-500 font-light">
            October 2024 Total
          </span>
          <StatsValue
            font-classes="!text-black font-extrabold text-xl"
            :value="
              formatCurrency('en-US', true).format(
                denialsStore.monthlyTotal.value
              )
            "
          >
          </StatsValue>
          <span class="flex flex-row justify-center items-center gap-2">
            <StatsValue
              font-classes="font-extrabold text-large"
              :value="denialsStore.monthlyTotal.percentageValue.toString()"
              :percentage="true"
              :arrow-ternary="denialsStore.monthlyTotal.arrowTernary"
              :color-ternary="denialsStore.monthlyTotal.colorTernary"
            >
            </StatsValue>
            <span class="text-gray-500 font-light">vs September 2024</span>
          </span>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24">
          <span class="text-sm text-gray-500 font-light">
            April 2024 - September 2025 Average
          </span>
          <StatsValue
            font-classes="!text-black font-extrabold text-xl"
            :value="
              formatCurrency('en-US', true).format(
                denialsStore.halfYearAverage.value
              )
            "
          >
          </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24">
          <span class="text-sm text-gray-500 font-light">
            October 2025 vs 6 Month Average
          </span>
          <StatsValue
            font-classes="!text-black font-extrabold text-xl"
            :value="
              formatCurrency('en-US', true).format(
                denialsStore.monthlyVS6MonthAverage.value
              )
            "
          >
          </StatsValue>
          <span class="flex flex-row justify-center items-center gap-2">
            <StatsValue
              font-classes="font-extrabold text-large"
              :value="
                denialsStore.monthlyVS6MonthAverage.percentageValue.toString()
              "
              :percentage="true"
              :arrow-ternary="denialsStore.monthlyVS6MonthAverage.arrowTernary"
              :color-ternary="denialsStore.monthlyVS6MonthAverage.colorTernary"
            >
            </StatsValue>
            <span class="text-gray-500 font-light">vs September 2024</span>
          </span>
        </StatsCard>
      </StatsGroupCard>
      <div class="flex flex-row gap-4 justify-between">
        <StatsGroupCard
          size-classes="w-1/2"
          :slot-container-classes="'flex flex-col justify-between items-center gap-4 p-4'"
          group-title="Financial Class Insights"
        >
          <StatsTable
            :headers="denialsStore.financialClassInsights[0].headers"
            :data="denialsStore.financialClassInsights[0].data"
          >
          </StatsTable>
          <StatsTable
            :headers="denialsStore.financialClassInsights[1].headers"
            :data="denialsStore.financialClassInsights[1].data"
          >
          </StatsTable>
        </StatsGroupCard>
        <StatsGroupCard
          size-classes="w-1/2"
          :slot-container-classes="'flex flex-col justify-between items-center gap-4 p-4'"
          group-title="Category Insights"
        >
          <StatsTable
            :headers="denialsStore.categoryInsights[0].headers"
            :data="denialsStore.categoryInsights[0].data"
          >
          </StatsTable>
          <StatsTable
            :headers="denialsStore.categoryInsights[1].headers"
            :data="denialsStore.categoryInsights[1].data"
          >
          </StatsTable>
        </StatsGroupCard>
      </div>
    </div>
  </PageView>
</template>
<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import StatsGroupCard from '@/components/stats/Cards/StatsGroupCard.vue';
import StatsValue from '@/components/stats/Misc/StatsValue.vue';
import StatsCard from '@/components/stats/Cards/StatsCard.vue';
import StatsTable from '@/components/stats/table/StatsTable.vue';
import DenialFilterModal from '@/components/stats/DenialFilterModal.vue';
import { useDenialsStore } from '@/stores/stats/useDenialsStats';
import { formatCurrency } from '@/utils/helpers';
import { ref } from 'vue';

const denialsStore = useDenialsStore();

const dateRange = `${denialsStore.startDate} - ${denialsStore.endDate}`;

const showPopup = ref(false);

function openPopup() {
  showPopup.value = true;
}

function hidePopup() {
  showPopup.value = false;
}
const activeTab = 'Overview';
</script>
<style scoped></style>
