<template>
  <TableHeaderControls class="sticky top-0 left-0 z-50"></TableHeaderControls>

  <table class="rounded-b-lg w-full text-sm text-left text-gray-500">
    <thead class="text-sm bg-white sticky top-[122px]">
      <tr>
        <template v-for="(header, index) in USERS_HEADERS" :key="index">
          <TableHeader
            :id="header.key"
            :title="header.value"
            :show-sort="header.sort"
            :width="header.width"
            :style="header.style"
            class="px-4 py-3 bg-gray-100"
            @sort="sortUsers"
          />
        </template>
      </tr>
    </thead>
    <td
      v-if="admin_store.usersLoading === true"
      :colspan="USERS_HEADERS.length"
    >
      <div class="flex items-center justify-center">
        <LoadingSpinner />
      </div>
    </td>
    <tbody>
      <template v-for="(item, index) in admin_store.filteredUsers" :key="index">
        <tr :data-index="index" class="z-10 border-b">
          <td
            v-for="(header, tableDataIndex) in USERS_HEADERS"
            :key="tableDataIndex"
            class="ml-1 px-4"
          >
            <TableCellViewFlowBite
              :item="item"
              :header="header"
              :index="`${index}-${tableDataIndex}`"
            />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script setup>
import { onMounted } from 'vue';
import TableHeader from '@/components/table/TableHeader.vue';
import TableHeaderControls from '@/components/table/TableHeaderControls.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useAdminStore } from '@/stores/useAdmin';
import { initFlowbite } from 'flowbite';
import TableCellViewFlowBite from '@/views/common/TableCellViewFlowBite.vue';

const admin_store = useAdminStore();

const USERS_HEADERS = [
  { key: 'user_photo', value: '', width: '8em' },
  { key: 'first_name', value: 'First Name', sort: true, width: '10em' },
  { key: 'last_name', value: 'Last Name', sort: true, width: '15em' },
  { key: 'email', value: 'Email', sort: true, width: '25%' },
  {
    key: 'informal_title',
    value: 'User Role',
    sort: false,
    width: '35em',
    style: 'pl-2',
  },
  {
    key: 'last_known_login',
    value: 'Last Login',
    sort: true,
    width: '35em',
    format: 'Pp',
    truncateTimezone: false,
  },
  {
    key: 'is_active',
    value: 'Active',
    sort: false,
    width: '15em',
    style: 'pl-2',
  },
  { key: 'edit_user', value: 'Edit', width: '5em' },
];

onMounted(async () => {
  admin_store.searchCriteria = '';
  if (admin_store.usersLoading == false) await admin_store.getUsers();
  initFlowbite();
});

function sortUsers(order = 'asc', id) {
  admin_store.filteredUsers.value = admin_store.sortUsers(order, id);
}
</script>
