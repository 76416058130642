<template>
  <div class="flex flex-col gap-3">
    <div class="flex gap-3">
      <StatsDatePicker
        ref="startDatePicker"
        label="Start Date"
        uniqueIdString="workGapStartDate"
        placeholder="MM/DD/YYYY"
        :model-value="startDate"
        :maxDate="endDate"
        @update="
          {
            newDate => {
              startDate = newDate;
            };
          }
        "
      />
      <StatsDatePicker
        ref="endDatePicker"
        label="End Date"
        uniqueIdString="workGapEndDate"
        placeholder="MM/DD/YYYY"
        :model-value="endDate"
        :minDate="startDate"
        @update="
          {
            newDate => {
              endDate = newDate;
            };
          }
        "
      />
    </div>
    <div class="flex flex-col gap-3">
      <span class="font-bold p-0.5 text-gray-900 text-sm">Billing Type</span>
      <RadioButtons
        ref="billingTypeRef"
        :button-options="radioButtonOptions"
        @select="
          {
            selection => {
              billingTypeSelection = radioButtonOptions[selection];
            };
          }
        "
      />
    </div>
    <div class="flex flex-col gap-1">
      <span class="font-bold p-0.5 text-gray-900 text-sm"
        >Additional Criteria</span
      >
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="billingProviderDropdownRef"
          unique-id-string="denials-billing-provider-dropdown"
          variant="secondary"
          text-key="billingProvider"
          :options="denialStore.billingProviderOptions"
          button-text="Billing Provider"
          @update-selection="
            selection => {
              billingProviderSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="billingEntityDropdownRef"
          unique-id-string="denials-billing-entity-dropdown"
          variant="secondary"
          text-key="billingEntity"
          :options="denialStore.billingEntityOptions"
          button-text="Billing Entity"
          @update-selection="
            selection => {
              billingEntitySelection = selection;
            }
          "
        />
      </div>
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="locationDropdownRef"
          unique-id-string="denials-location-dropdown"
          variant="secondary"
          text-key="location"
          :options="denialStore.locationOptions"
          button-text="Location"
          @update-selection="
            selection => {
              locationSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="departmentDropdownRef"
          unique-id-string="denials-department-dropdown"
          variant="secondary"
          text-key="department"
          :options="denialStore.departmentOptions"
          button-text="Department"
          @update-selection="
            selection => {
              departmentSelection = selection;
            }
          "
        />
      </div>
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="financialClassDropdownRef"
          unique-id-string="denials-financial-class-dropdown"
          variant="secondary"
          text-key="financialClass"
          :options="denialStore.financialClassOptions"
          button-text="Financial Class"
          @update-selection="
            selection => {
              financialClassSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="payerPlanDropdownRef"
          unique-id-string="denials-payer-plan-dropdown"
          variant="secondary"
          text-key="payerPlan"
          :options="denialStore.payerPlanOptions"
          button-text="Payer/Plan"
          @update-selection="
            selection => {
              payerPlanSelection = selection;
            }
          "
        />
      </div>
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="denialCategoryCodeDropdownRef"
          unique-id-string="denials-denial-category-code-dropdown"
          variant="secondary"
          text-key="denialCategoryCode"
          :options="denialStore.denialCategoryCodeOptions"
          button-text="Denial Category/Code"
          @update-selection="
            selection => {
              denialCategoryCodeSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="denialGroupingDropdownRef"
          unique-id-string="denials-denial-grouping-dropdown"
          variant="secondary"
          text-key="denialGrouping"
          :options="denialStore.denialGroupingOptions"
          button-text="Denial Grouping"
          @update-selection="
            selection => {
              denialGroupingSelection = selection;
            }
          "
        />
      </div>
    </div>
  </div>
  <hr class="h-px my-10 bg-gray-200 border-0 dark:bg-gray-700" />
  <div class="flex flex-row w-1/4 pr-4 self-end gap-2">
    <OutlinedButtonFlowBite
      class="text-gray-900 font-bold"
      @click="resetFilters"
    >
      Reset
    </OutlinedButtonFlowBite>
    <SolidButtonFlowBite
      variant="tertiary"
      class="!font-bold"
      @click="applyFilters"
    >
      Apply
    </SolidButtonFlowBite>
  </div>
</template>

<script setup>
import { useDenialsStore } from '@/stores/stats/useDenialsStats';
import StatsDatePicker from './StatsDatePicker.vue';
import OutlinedButtonFlowBite from '../buttons/OutlinedButtonFlowBite.vue';
import SolidButtonFlowBite from '../buttons/SolidButtonFlowBite.vue';
import { ref } from 'vue';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '@/utils/ogFakeData';
import StatsMultiSelectFlowbite from './StatsMultiSelectFlowbite.vue';
import RadioButtons from './RadioButtons.vue';

const denialStore = useDenialsStore();

const radioButtonOptions = ['Show All', 'Hospital', 'Physician'];

const billingProviderSelection = ref([]);
const billingEntitySelection = ref([]);
const locationSelection = ref([]);
const departmentSelection = ref([]);
const financialClassSelection = ref([]);
const payerPlanSelection = ref([]);
const denialCategoryCodeSelection = ref([]);
const denialGroupingSelection = ref([]);

const startDate = ref(denialStore.startDate);
const endDate = ref(denialStore.endDate);

const userDropdownRef = ref(null);
const activityGapDropdownRef = ref(null);
const startDatePicker = ref(null);
const endDatePicker = ref(null);

const userDropdownOptions = denialStore.OGFakeWorkGapData.map(
  (entry, index) => {
    return { id: index, username: entry.user };
  }
);
const activityGapDropdownOptions = [
  { id: 1, activityGap: '1.5 - 3 hours' },
  { id: 2, activityGap: '3+ hours' },
];

const resetFilters = () => {
  userDropdownRef.value.clearSelection();
  activityGapDropdownRef.value.clearSelection();

  startDatePicker.value.clearSelection(DEFAULT_START_DATE);
  endDatePicker.value.clearSelection(DEFAULT_END_DATE);
  denialStore.resetFilters();
};

const applyFilters = () => {
  denialStore.startDate = startDate.value;
  denialStore.endDate = endDate.value;
  denialStore.applyFilters();
};
</script>
