<template>
  <table
    class="text-sm text-left rounded-md text-gray-500 ![--tw-drop-shadow:none]"
    :class="{
      [props.tableClasses]: true,
      [props.tableSizeClasses]: true,
    }"
  >
    <thead class="text-sm bg-white rounded-md">
      <tr>
        <template v-for="(header, index) in headers" :key="index">
          <TableHeader
            :id="header.key"
            :centered="header.centered ? true : false"
            :title="header.value"
            :width="header.width"
            :style="header.style"
            class="px-4 py-3 bg-gray-100 !justify-start"
          />
        </template>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, index) in data" :key="index">
        <tr :data-index="index" class="border-b">
          <td
            v-for="(header, tableDataIndex) in headers"
            :key="tableDataIndex"
            class="ml-1 px-4 border-2 border-solid border-gray-100"
          >
            <StatsTableCell
              :item="item"
              :index="index"
              :header="header"
              :width="header.width"
              :text-color="header.key == 'total' ? 'secondary' : 'black'"
              :span-classes="
                statTableCellSpanClasses(header, item, tableDataIndex)
              "
              :div-classes="
                statTableCellDivClasses(header, item, tableDataIndex)
              "
            />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
import StatsTableCell from './StatsTableCell.vue';

//These are not that great of a solution to the problem I'm having with cells with different styles
//But they will do for now, open to other options
function statTableCellSpanClasses(header, item, tableDataIndex) {
  let classNames = 'text-center ';
  if (item[header.key].format && item[header.key].format == 'currency') {
    classNames += 'font-extrabold text-xl ';
  } else if (tableDataIndex == 0) {
    classNames += 'text-gray-600 font-bold';
  } else {
    classNames += 'font-bold ';
  }
  return classNames;
}

function statTableCellDivClasses(header, item, tableDataIndex) {
  let classNames = '';
  if (tableDataIndex == 0) {
    classNames += 'ml-4 ';
  }
  if (
    typeof item[header.key] == 'object' &&
    item[header.key].additionalPercentage
  ) {
    classNames += 'justify-between w-full pr-6 ';
  }
  return classNames;
}
const props = defineProps({
  tableClasses: {
    type: String,
    default: 'justify-center items-center rounded-md',
  },
  tableSizeClasses: {
    type: String,
    default: 'w-full',
  },
  headers: {
    type: Array,
    default: () => [],
  },
  data: {
    type: Array,
    default: () => [],
  },
});
</script>
