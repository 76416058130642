<template>
  <PageView page-classes="!overflow-x-auto" body-classes="min-w-[72rem]">
    <StatsTopNavBar :tab-items="navBarTabs" :selected-tab="activeTab">
      <template #modal-content>
        <DenialFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex items-center justify-between mb-8 w-full">
      <h1 class="text-rev-2xl text-primary font-bold">
        Denials {{ activeTab }}
      </h1>
      <div class="flex items-center gap-1">
        <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
        <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
      </div>
    </div>
    <div class="bg-white p-4 rounded-lg w-full">
      <div class="flex justify-between">
        <h2 class="font-bold text-xl">Total Denials</h2>
        <div class="flex gap-4 items-center">
          <ToggleComponent
            v-if="splitBy != 'none'"
            left-button-text="Stack 100"
            right-button-text="Column Chart"
            default-selected-button="right"
            @toggle="
              button => {
                isStacked = button == 'left';
              }
            "
          />
          <DropDownFlowBite
            unique-id-string="work-gap-split-by"
            text-key="text"
            :options="splitByOptions"
            variant="stats"
            @update-selection="handleUpdateSplitBy"
          />
        </div>
      </div>
      <hr class="h-px my-10 bg-gray-200 border-0 dark:bg-gray-700" />
      <div v-if="loading" class="flex justify-center">
        <LoadingSpinner />
      </div>
      <div v-else>
        <div v-show="!isExpanded">
          <ChartComponent
            chart-id="work-gap-breakout-chart"
            :stack100="isStacked"
            :chart-data="chartData"
            :currency="true"
          />
        </div>

        <!-- Table -->
        <div v-if="splitBy !== 'none'">
          <table
            class="text-sm text-left text-gray-500 ![--tw-drop-shadow:none]"
          >
            <thead class="text-sm bg-white">
              <tr>
                <template v-for="(header, index) in fakeHeaders" :key="index">
                  <TableHeader
                    :id="header.key.toString()"
                    :title="header.value"
                    :show-sort="header.sort"
                    :width="header.width"
                    :style="header.style"
                    :show-arrows="true"
                    :expanded="isExpanded"
                    class="px-4 py-3 bg-gray-100"
                    @sort="sortFields"
                    @expand="toggleExpanded"
                  />
                </template>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(item, index) in chartData.sortableSeries"
                :key="index"
              >
                <tr
                  :data-index="index"
                  class="border-b"
                  :class="{ 'bg-gray-100 font-bold': item.name == 'Total' }"
                >
                  <td
                    v-for="(header, tableDataIndex) in fakeHeaders"
                    :key="tableDataIndex"
                    class="ml-1 px-4"
                  >
                    <StatsTableCell
                      :item="
                        tableDataIndex <= 0
                          ? item
                          : tableDataIndex == 1 && header.key == 'secondName'
                          ? item
                          : tableDataIndex < fakeHeaders.length - 1
                          ? item.data
                          : {
                              total: item.data.reduce(
                                (partialSum, a) => partialSum + a,
                                0
                              ),
                            }
                      "
                      :cell-format="
                        tableDataIndex <= 0 ||
                        (tableDataIndex == 1 && header.key == 'secondName')
                          ? null
                          : 'currency'
                      "
                      :header="header"
                      :width="header.width"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="flex pt-4 justify-between items-center">
            <StatsPagination
              :records="chartData.seriesLength"
              :limit="pageLimit"
              :page="pageNumber"
              @paginate="paginate"
            />
          </div>
        </div>
      </div>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import DenialFilterModal from '@/components/stats/DenialFilterModal.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import { useDenialsStore } from '@/stores/stats/useDenialsStats';
import DropDownFlowBite from '@/components/forms/DropDownFlowBite.vue';
import ToggleComponent from '@/components/stats/Misc/ToggleComponent.vue';
import ChartComponent from '@/components/stats/ChartComponent.vue';
import { onMounted, ref } from 'vue';
import TableHeader from '@/components/table/TableHeader.vue';
import StatsTableCell from '@/components/stats/table/StatsTableCell.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import StatsPagination from '@/components/stats/table/StatsPagination.vue';

const denialsStore = useDenialsStore();

const isStacked = ref(false);
const splitBy = ref('none');
const isExpanded = ref(false);
const chartData = ref([]);

const loading = ref(true);
const fakeHeaders = ref([]);

//Putting page limit stuff here mainly for testing
const pageLimit = ref(2);
const pageNumber = ref(1);
const sortColumn = ref(null);
const sortDirection = ref(null);

const activeTab = 'Breakout';
const dateRange = `${denialsStore.startDate} - ${denialsStore.endDate}`;

onMounted(() => {
  chartData.value = denialsStore.createSeriesAndCategoriesForChart(
    splitBy.value,
    pageLimit.value,
    pageNumber.value,
    sortColumn.value,
    sortDirection.value,
    'denial_amount'
  );
  loading.value = false;
  fakeHeaders.value = [
    ...chartData.value.categories.map((category, index) => {
      const fullCategory = category.join(' ');
      return {
        key: index,
        value: fullCategory.charAt(0).toUpperCase() + fullCategory.slice(1),
        width: '16rem',
        sort: true,
      };
    }),
    {
      key: 'expand',
      width: '16rem',
      sort: true,
    },
  ];
});

const splitByOptions = [
  {
    id: 'none',
    text: 'None',
  },
  {
    id: 'billing_provider',
    text: 'Billing Provider',
  },
  {
    id: 'billing_entity',
    text: 'Billing Entity',
  },
  {
    id: 'location',
    text: 'Location',
  },
  {
    id: 'department',
    text: 'Department',
  },
  {
    id: 'financial_class',
    text: 'Financial Class',
  },
  {
    id: 'current_payer',
    text: 'Payer/Plan',
  },
  {
    id: 'denial_category',
    text: 'Denial Category Code',
  },
  {
    id: 'denial_grouping',
    text: 'Denial Grouping',
  },
];

const sortFields = (order = 'asc', field) => {
  sortColumn.value = field;
  sortDirection.value = order;
  pageNumber.value = 1;
  chartData.value.sortableSeries =
    denialsStore.createSeriesAndCategoriesForChart(
      splitBy.value,
      pageLimit.value,
      pageNumber.value,
      sortColumn.value,
      sortDirection.value,
      'denial_amount'
    ).sortableSeries;
};

const handleUpdateSplitBy = selection => {
  sortDirection.value = null;
  sortColumn.value = null;
  splitBy.value = selection.id;
  if (selection.id == 'none') {
    isExpanded.value = false;
  }

  chartData.value = denialsStore.createSeriesAndCategoriesForChart(
    splitBy.value,
    pageLimit.value,
    pageNumber.value,
    sortColumn.value,
    sortDirection.value,
    'denial_amount'
  );

  if (fakeHeaders.value.some(header => header.key == 'secondName')) {
    fakeHeaders.value.splice(1, 1);
  }
  fakeHeaders.value[0] = {
    key: 'name',
    value: selection.text,
    width: '8rem',
    sort: true,
  };
  if (selection.id == 'current_payer') {
    fakeHeaders.value.splice(1, 0, {
      key: 'secondName',
      value: 'Plan',
      width: '8rem',
      sort: true,
    });
  } else if (selection.id == 'denial_category') {
    fakeHeaders.value.splice(1, 0, {
      key: 'secondName',
      value: 'Code',
      width: '8rem',
      sort: true,
    });
  }
};

const toggleExpanded = () => {
  isExpanded.value = !isExpanded.value;
  //We're gonna want to change this when we have actual data
  if (isExpanded.value) {
    pageLimit.value = 15;
  } else {
    pageLimit.value = 2;
  }
  paginate(pageNumber.value);
};

function paginate(page) {
  //Logic here
  pageNumber.value = page;
  chartData.value.sortableSeries =
    denialsStore.createSeriesAndCategoriesForChart(
      splitBy.value,
      pageLimit.value,
      pageNumber.value,
      sortColumn.value,
      sortDirection.value,
      'denial_amount'
    ).sortableSeries;
}
</script>
<style scoped></style>
