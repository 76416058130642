<template>
  <PageView page-classes="!overflow-x-auto">
    <StatsTopNavBar
      modal-title="AR - Advanced Filters"
      :tab-items="navBarTabs"
      :selected-tab="activeTab"
      :filters-applied="arStatsStore.filtersApplied"
    >
      <template #modal-content>
        <ARFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between mb-4 w-full">
        <div class="flex flex-col gap-2">
          <div class="flex items-center gap-2 w-fit">
            <h1 class="text-rev-2xl text-primary font-bold">
              AR {{ activeTab }}
            </h1>
            <div
              class="relative flex flex-col items-center justify-center bg-tertiary rounded-xl text-white w-[1.25rem] h-[1.25rem]"
              @mouseenter="openPopup"
              @mouseleave="hidePopup"
            >
              <div
                v-if="showPopup"
                class="absolute left-8 z-10 text-sm whitespace-nowrap flex flex-col items-center justify-center bg-white border-2 border-solid border-tertiary rounded-xl text-black w-fit px-3 py-2"
              >
                Date and Balance filters cannot be applied to this page
              </div>
              !
            </div>
          </div>
          <p class="text-gray-600 text-sm">
            Average calculation does not include current month
          </p>
        </div>
        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
            <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
          </div>
          <div class="flex items-center gap-1 justify-end">
            <span class="text-rev-sm text-gray-500 font-bold"
              >Last Updated:</span
            >
            <span class="text-rev-sm text-gray-400">{{
              arStatsStore.lastUpdated
            }}</span>
          </div>
        </div>
      </div>
      <StatsGroupCard groupTitle="Current AR" size-classes="w-full h-fit">
        <StatsCard
          color-classes="bg-gray-75"
          container-classes="justify-center items-center rounded-md gap-2"
          size-classes="w-[12%] h-32"
        >
          <StatsValue :value="arStatsStore.currentARCount" />
          <span class="text-class">Count</span>
        </StatsCard>
        <StatsCard
          color-classes="bg-gray-75"
          container-classes="justify-center items-center rounded-md gap-2"
          size-classes="w-[12%] h-32"
        >
          <StatsValue :value="arStatsStore.currentARBalance" :currency="true" />
          <span class="text-class">Balance</span> </StatsCard
        ><StatsCard
          color-classes="bg-gray-75"
          container-classes="justify-center items-center rounded-md gap-2"
          size-classes="w-[12%] h-32"
        >
          <StatsValue
            :value="arStatsStore.percentGreaterThan90"
            :percentage="true"
          />
          <span class="text-class">% of AR > 90</span> </StatsCard
        ><StatsCard
          color-classes="bg-gray-75"
          container-classes="justify-center items-center rounded-md gap-2"
          size-classes="w-[12%] h-32"
        >
          <StatsValue
            :value="arStatsStore.percentGreaterThan180"
            :percentage="true"
          />
          <span class="text-class">% of AR > 180</span>
        </StatsCard>
        <StatsCard
          color-classes="bg-gray-75"
          container-classes="justify-center items-center rounded-md gap-2"
          size-classes="w-[12%] h-32"
        >
          <StatsValue
            :value="arStatsStore.percentGreaterThan365"
            :percentage="true"
          />
          <span class="text-class">% of AR > 365</span>
        </StatsCard>
        <StatsCard
          size-classes="w-1/4 h-32"
          container-classes="cursor-pointer"
          @click="router.push('/stats/ar/high-dollar')"
        >
          <div
            class="flex bg-bad-red rounded-t-md p-2 w-full justify-center text-white font-bold"
          >
            High Dollar: Needs Attention
          </div>
          <div
            class="flex justify-around w-full bg-gray-75 rounded-b-md p-2 h-full items-center"
          >
            <div class="flex flex-col items-center gap-1">
              <StatsValue
                :value="arStatsStore.highDollarCount"
                :color-ternary="3"
              />
              <span class="text-class">Count</span>
            </div>
            <div class="flex flex-col items-center gap-1">
              <StatsValue
                :value="arStatsStore.highDollarAmount"
                :color-ternary="3"
                :currency="true"
              />
              <span class="text-class">Amount</span>
            </div>
            <div class="flex flex-col items-center gap-1">
              <StatsValue
                :value="arStatsStore.highDollarPercentage"
                :percentage="true"
                :color-ternary="3"
              />
              <span class="text-class">Percentage</span>
            </div>
          </div>
        </StatsCard>
      </StatsGroupCard>
      <div class="bg-white rounded-md flex flex-col">
        <h2 class="font-bold text-rev-xl items-start p-4">Trends</h2>
        <div class="flex justify-around items-center h-32">
          <div
            class="flex flex-col items-center text-sm w-1/4 border-r h-24 justify-center"
          >
            <span class="text-class">October 2024 Total</span>
            <StatsValue
              :value="arStatsStore.currentMonthTrendTotal"
              :color-ternary="3"
              :currency="true"
              size-classes="text-xl"
            />
          </div>
          <div
            class="flex flex-col items-center text-sm w-1/4 border-r h-24 justify-center"
          >
            <span class="text-class">April 2024 - September 2025 Average</span>
            <StatsValue
              :value="arStatsStore.dateRangeAverage"
              :color-ternary="3"
              :currency="true"
              size-classes="text-xl"
            />
          </div>
          <div
            class="flex flex-col items-center text-sm w-1/4 border-r h-24 justify-center"
          >
            <span class="text-class">October 2024 vs 6 Month Average</span>
            <StatsValue
              :value="arStatsStore.monthVsSixMonthAverage"
              :color-ternary="3"
              :currency="true"
              size-classes="text-xl"
            />
            <div class="flex items-center">
              <StatsValue
                :arrow-ternary="2"
                :percentage="true"
                :color-ternary="1"
                size-classes="text-lg"
                :value="arStatsStore.percentDiffCurrency"
              />
              <span class="text-class pl-2">From 6 month avg</span>
            </div>
          </div>
          <div
            class="flex flex-col items-center text-sm w-1/4 border-r h-24 justify-center"
          >
            <span class="text-class">October 2024 vs 6 Month Average</span>
            <StatsValue
              :value="arStatsStore.monthVsSixMonthAverageAgain"
              :color-ternary="3"
              :currency="true"
              size-classes="text-xl"
            />
            <div class="flex items-center">
              <StatsValue
                :arrow-ternary="1"
                :percentage="true"
                :color-ternary="2"
                size-classes="text-lg"
                :value="arStatsStore.percentDiff"
              />
              <span class="text-class pl-2">From 6 month avg</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import { useArStatsStore } from '@/stores/stats/useARStats.js';
import ARFilterModal from '@/components/stats/modals/ARFilterModal.vue';
import { ref } from 'vue';
import StatsGroupCard from '@/components/stats/Cards/StatsGroupCard.vue';
import StatsCard from '@/components/stats/Cards/StatsCard.vue';
import StatsValue from '@/components/stats/Misc/StatsValue.vue';
import router from '@/router';

const arStatsStore = useArStatsStore();

const activeTab = 'Overview';
const dateRange = `${arStatsStore.startDate} - ${arStatsStore.endDate}`;

const showPopup = ref(false);

function openPopup() {
  showPopup.value = true;
}

function hidePopup() {
  showPopup.value = false;
}
</script>
<style scoped>
.text-class {
  @apply font-light text-gray-500 text-sm;
}
</style>
