function getFakeMin() {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return `${Math.round((array[0] / (0xffffffff + 1)) * 2000)} min`;
}

function getFakeNumberSeq(n) {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return Math.round(
    10 ** (n - 1) + (array[0] / (0xffffffff + 1)) * 9 * 10 ** (n - 1)
  );
}

function getFakeDollarAmount() {
  const array = new Uint32Array(2);
  window.crypto.getRandomValues(array);
  const n = Math.round((array[0] / (0xffffffff + 1)) * 5);
  return '$' + ((array[1] / (0xffffffff + 1)) * 9 * 10 ** (n - 1)).toFixed(2);
}

function getRandomPhase() {
  const PHASE = ['Billing', 'Follow Up', 'Self Pay'];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return PHASE[Math.floor((array[0] / (0xffffffff + 1)) * PHASE.length)];
}

function getRandomReason() {
  const REASONS = [
    'Catch-All',
    'Appeal Sent',
    'Additional Info',
    'Bundled Services',
    'Claims / Billing',
    'Coding',
    'Contractual',
    'Credentialing/Enrollment',
    'Duplicate',
    'Eligibility',
    'Informational',
    'Medical Necessity',
    'No Auth/Referral',
    'Non-Covered',
    'Patient Liability',
    'Settlement',
    'Timely Filing',
    'Unmapped',
    'No Response < 30 days',
    'No Response > 30 days,',
    'Denial Review',
    'Payment Review',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return REASONS[Math.floor((array[0] / (0xffffffff + 1)) * REASONS.length)];
}

function getRandomPayer() {
  const PAYER_PLANS = ['Payer One', 'Payer Two', 'Payer Three'];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return PAYER_PLANS[
    Math.floor((array[0] / (0xffffffff + 1)) * PAYER_PLANS.length)
  ];
}

function getRandomStatus() {
  const STATUSES = ['Needs Attention', 'Recently Billed', 'Recently Worked'];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return STATUSES[Math.floor((array[0] / (0xffffffff + 1)) * STATUSES.length)];
}

function getRandomFinClass() {
  const FIN_CLASSES = [
    'Test One',
    'Test Two',
    'Test Three',
    'Test Four',
    'Test Five',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return FIN_CLASSES[
    Math.floor((array[0] / (0xffffffff + 1)) * FIN_CLASSES.length)
  ];
}

function getFakeActivityCode() {
  const FAKE_CODES = [
    'Fake Code One',
    'Fake Code Two',
    'Fake Code Three',
    'Fake Code Four',
    'Fake Code Five',
    'Other',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return FAKE_CODES[
    Math.floor((array[0] / (0xffffffff + 1)) * FAKE_CODES.length)
  ];
}

function getRandomBillingProvider() {
  const PROVIDERS = [
    'Provider 1',
    'Provider 2',
    'Provider 3',
    'Provider 4',
    'Provider 5',
    'Provider 6',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return PROVIDERS[
    Math.floor((array[0] / (0xffffffff + 1)) * PROVIDERS.length)
  ];
}

function getRandomBillingEntity() {
  const ENTITIES = [
    'Entity 1',
    'Entity 2',
    'Entity 3',
    'Entity 4',
    'Entity 5',
    'Entity 6',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return ENTITIES[Math.floor((array[0] / (0xffffffff + 1)) * ENTITIES.length)];
}

function getRandomLocation() {
  const LOCATIONS = [
    'UIHC',
    'Chicago',
    'New York',
    'LA',
    'Houston',
    'Iowa City',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return LOCATIONS[
    Math.floor((array[0] / (0xffffffff + 1)) * LOCATIONS.length)
  ];
}

function getRandomDepartment() {
  const DEPARTMENTS = [
    'SCP',
    'Department 1',
    'Department 2',
    'Department 3',
    'Department 4',
    'Department 5',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return DEPARTMENTS[
    Math.floor((array[0] / (0xffffffff + 1)) * DEPARTMENTS.length)
  ];
}

function getRandomPlan() {
  const PAYER_PLANS = ['Plan One', 'Plan Two', 'Plan Three', 'Plan Four'];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return PAYER_PLANS[
    Math.floor((array[0] / (0xffffffff + 1)) * PAYER_PLANS.length)
  ];
}

function getRandomDenialCategory() {
  const DENIAL_CATEGORY = [
    'Category One',
    'Category Two',
    'Category Three',
    'Category Four',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return DENIAL_CATEGORY[
    Math.floor((array[0] / (0xffffffff + 1)) * DENIAL_CATEGORY.length)
  ];
}

function getRandomDenialCode() {
  const DENIAL_CODE = ['200', '403', '291', '174', '123'];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return DENIAL_CODE[
    Math.floor((array[0] / (0xffffffff + 1)) * DENIAL_CODE.length)
  ];
}

function getRandomDenialGrouping() {
  const DENIAL_GROUPING = [
    'Group 1',
    'Group 2',
    'Group 3',
    'Group 4',
    'Group 5',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return DENIAL_GROUPING[
    Math.floor((array[0] / (0xffffffff + 1)) * DENIAL_GROUPING.length)
  ];
}

function getFakeDate() {
  const now = new Date();
  const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));
  const randomDate = new Date(
    sixMonthsAgo.getTime() +
      Math.random() * (Date.now() - sixMonthsAgo.getTime())
  );
  return randomDate.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
}

function getFakeNumberDate() {
  const now = new Date();
  const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));
  const randomDate = new Date(
    sixMonthsAgo.getTime() +
      Math.random() * (Date.now() - sixMonthsAgo.getTime())
  );
  // return randomDate;
  return randomDate.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

function getRandomAgeCategory() {
  const AGE_CATEGORIES = [
    '0-30 Days',
    '31-60 Days',
    '61-90 Days',
    '91-120 Days',
    '121-150 Days',
    '151-180 Days',
    '181-365 Days',
    '365+ Days',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return AGE_CATEGORIES[
    Math.floor((array[0] / (0xffffffff + 1)) * AGE_CATEGORIES.length)
  ];
}

function getRandomUser() {
  const FAKE_USER_LIST = [
    'Test1',
    'Test2',
    'Test3',
    'Test4',
    'Test5',
    'Test6',
    'Test7',
  ];
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return FAKE_USER_LIST[
    Math.floor((array[0] / (0xffffffff + 1)) * FAKE_USER_LIST.length)
  ];
}

//This will happen on the back end - just mimicking what will happen for now
export function fetchAndSortWorkGapData(fakeBody) {
  const { page, limit, direction, field } = fakeBody;
  const start = (page - 1) * limit;

  const rawFakeData = FAKE_WORK_GAP_DATA.map(entry => {
    return {
      ...entry,
    };
  });
  const getValue = item => {
    const value = item[field];
    if (field == 'date') {
      return new Date(value).getTime();
    } else if (
      field == 'denial_amount' ||
      field == 'account_number' ||
      field == 'visit_number'
    ) {
      return parseFloat(value.replace('$', ''));
    } else if (field == 'time') {
      let [theTime, period] = value.split(' ');
      let [hours, minutes] = theTime.split(':');
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      if (period === 'PM' && hours !== 12) hours += 12;
      if (period === 'AM' && hours === 12) hours = 0;
      return hours * 60 + minutes;
    }
    return value;
  };

  const sortedData = rawFakeData.sort((a, b) => {
    const valueA = getValue(a);
    const valueB = getValue(b);
    const multiplier = direction === 'asc' ? 1 : -1;
    return typeof valueA === 'number'
      ? (valueA - valueB) * multiplier
      : valueA.localeCompare(valueB) * multiplier;
  });

  return {
    totalRows: FAKE_WORK_GAP_DATA.length,
    fakeData: sortedData.slice(start, start + limit),
  };
}

export function fetchAndSortDenialData(fakeBody) {
  const { page, limit, direction, field } = fakeBody;
  const start = (page - 1) * limit;

  const rawFakeData = FAKE_DENIALS_DATA.map(entry => {
    const splitDate1 = entry.mrn.split(' at ');
    const splitDate2 = entry.denial_post_date.split(' at ');
    const splitDate3 = entry.service_start_date.split(' at ');
    const splitDate4 = entry.service_end_date.split(' at ');
    const {
      mrn,
      denial_post_date,
      service_start_date,
      service_end_date,
      ...rest
    } = entry;
    return {
      mrn: new Date(splitDate1[0]).toLocaleDateString(),
      denial_post_date: new Date(splitDate2[0]).toLocaleDateString(),
      service_start_date: new Date(splitDate3[0]).toLocaleDateString(),
      service_end_date: new Date(splitDate4[0]).toLocaleDateString(),
      time: splitDate1[1],
      ...rest,
    };
  });
  const getValue = item => {
    const value = item[field];
    if (
      field == 'mrn' ||
      field == 'denial_post_date' ||
      field == 'service_start_date' ||
      field == 'service_end_date'
    ) {
      return new Date(value).getTime();
    } else if (field == 'current_balance') {
      return parseFloat(value.replace('$', ''));
    } else if (field == 'time') {
      let [theTime, period] = value.split(' ');
      let [hours, minutes] = theTime.split(':');
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      if (period === 'PM' && hours !== 12) hours += 12;
      if (period === 'AM' && hours === 12) hours = 0;

      return hours * 60 + minutes;
    }
    return value;
  };

  const sortedData = rawFakeData.sort((a, b) => {
    const valueA = getValue(a);
    const valueB = getValue(b);
    const multiplier = direction === 'asc' ? 1 : -1;
    return typeof valueA === 'number'
      ? (valueA - valueB) * multiplier
      : valueA.localeCompare(valueB) * multiplier;
  });

  return {
    totalRows: FAKE_DENIALS_DATA.length,
    fakeData: sortedData.slice(start, start + limit),
  };
}

//This will happen on the back end - just mimicking what will happen for now
export function fetchAndSortActivityData(fakeBody) {
  const { page, limit, direction, field } = fakeBody;
  const start = (page - 1) * limit;

  const rawFakeData = FAKE_ACTIVITY_DATA.map(entry => {
    const splitDate = entry.date.split(' at ');
    const { date, ...rest } = entry;
    return {
      date: new Date(splitDate[0]).toLocaleDateString(),
      time: splitDate[1],
      ...rest,
    };
  });
  const getValue = item => {
    const value = item[field];
    if (field == 'date') {
      return new Date(value).getTime();
    } else if (field == 'current_balance') {
      return parseFloat(value.replace('$', ''));
    } else if (field == 'time') {
      let [theTime, period] = value.split(' ');
      let [hours, minutes] = theTime.split(':');
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      if (period === 'PM' && hours !== 12) hours += 12;
      if (period === 'AM' && hours === 12) hours = 0;
      return hours * 60 + minutes;
    }
    return value;
  };

  const sortedData = rawFakeData.sort((a, b) => {
    const valueA = getValue(a);
    const valueB = getValue(b);
    const multiplier = direction === 'asc' ? 1 : -1;
    return typeof valueA === 'number'
      ? (valueA - valueB) * multiplier
      : valueA.localeCompare(valueB) * multiplier;
  });

  return {
    totalRows: FAKE_ACTIVITY_DATA.length,
    fakeData: sortedData.slice(start, start + limit),
  };
}

//This will happen on the back end - just mimicking what will happen for now
export function fetchAndSortARData(fakeBody, fakeARData = FAKE_AR_DATA) {
  const { page, limit, direction, field, filter } = fakeBody;
  const start = (page - 1) * limit;

  const getValue = item => {
    const value = item[field];
    if (
      field == 'serviceStartDate' ||
      field == 'serviceEndDate' ||
      field == 'lastDenialDate' ||
      field == 'lastPaymentDate' ||
      field == 'lastClaimDate' ||
      field == 'lastActivityDate'
    ) {
      return new Date(value).getTime();
    } else if (
      field == 'currentBalance' ||
      field == 'totalCharges' ||
      field == 'totalPayments' ||
      field == 'totalAdjustments'
    ) {
      return parseFloat(value.replace('$', ''));
    }
    return value;
  };

  if (filter) {
    if (filter !== 'all') {
      fakeARData = fakeARData.filter(row => row.status == filter);
    }
  }

  const sortedData = fakeARData.sort((a, b) => {
    const valueA = getValue(a);
    const valueB = getValue(b);

    const multiplier = direction === 'asc' ? 1 : -1;
    return typeof valueA === 'number'
      ? (valueA - valueB) * multiplier
      : valueA.localeCompare(valueB) * multiplier;
  });

  return {
    totalRows: fakeARData.length,
    fakeData: sortedData.slice(start, start + limit),
  };
}

let cachedHighDollarData = null;

export function addStatusesToHighDollar() {
  // Only generate statuses once, then reuse the same data
  if (!cachedHighDollarData) {
    cachedHighDollarData = FAKE_AR_DATA.map(element => {
      return {
        ...element,
        status: getRandomStatus(),
      };
    });
  }
  return cachedHighDollarData;
}

export const DEFAULT_START_DATE = '04/01/2024';
export const DEFAULT_END_DATE = '10/31/2024';

export const ACTIVITY_TYPE_OPTIONS = [
  {
    id: 1,
    activityType: 'Appeal Sent',
  },
  {
    id: 2,
    activityType: 'Adjustment Requested',
  },
  {
    id: 3,
    activityType: 'Billed - Corrected Claim/Rebill',
  },
  {
    id: 4,
    activityType: 'Pending - Appeal in Process',
  },
  {
    id: 5,
    activityType: 'Triaged - Coding',
  },
];

export const BILLING_PROVIDER_OPTIONS = [
  {
    id: 1,
    billingProvider: 'Test 1',
  },
  {
    id: 2,
    billingProvider: 'Test 2',
  },
  {
    id: 3,
    billingProvider: 'Test 3',
  },
];

export const BILLING_ENTITY_OPTIONS = [
  {
    id: 1,
    billingEntity: 'ABC Hospital',
  },
  {
    id: 2,
    billingEntity: 'ABC Physician Network',
  },
  {
    id: 3,
    billingEntity: 'XYZ Hospital',
  },
];

export const USER_OPTIONS = [
  {
    id: 1,
    user: 'Test 1',
  },
  {
    id: 2,
    user: 'Test 2',
  },
  {
    id: 3,
    user: 'Test 3',
  },
];

export const LOCATION_OPTIONS = [
  {
    id: 1,
    location: 'Test Location 1',
  },
  {
    id: 2,
    location: 'Test Location 2',
  },
  {
    id: 3,
    location: 'Test Location 3',
  },
];

export const DEPARTMENT_OPTIONS = [
  {
    id: 1,
    department: 'Test Department 1',
  },
  {
    id: 2,
    department: 'Test Department 2',
  },
  {
    id: 3,
    department: 'Test Department 3',
  },
];

export const FINANCIAL_CLASS_OPTIONS = [
  {
    id: 1,
    financialClass: 'Test Fin Class 1',
  },
  {
    id: 2,
    financialClass: 'Test Fin Class 2',
  },
  {
    id: 3,
    financialClass: 'Test Fin Class 3',
  },
];
export const BALANCE_BUCKET_OPTIONS = [
  {
    id: 1,
    balanceBucket: '$100-$499',
  },
  {
    id: 2,
    balanceBucket: '$10,000-$24,999',
  },
  {
    id: 3,
    balanceBucket: '>$100,000',
  },
];
export const AGE_CATEGORY_OPTIONS = [
  {
    id: 1,
    ageCategory: '0-30',
  },
  {
    id: 2,
    ageCategory: '31-60',
  },
  {
    id: 3,
    ageCategory: '60+',
  },
];
export const LAST_ACTIVITY_TYPE_OPTIONS = [
  {
    id: 1,
    lastActivityType: 'Test Activity Type 1',
  },
  {
    id: 2,
    lastActivityType: 'Test Activity Type 2',
  },
  {
    id: 3,
    lastActivityType: 'Test Activity Type 3',
  },
];

export const PAYER_PLAN_OPTIONS = [
  {
    id: 1,
    payerPlan: 'Test Payer 1',
  },
  {
    id: 2,
    payerPlan: 'Test Payer 2',
  },
  {
    id: 3,
    payerPlan: 'Test Payer 3',
  },
  {
    id: 4,
    payerPlan: 'Test Payer 4',
  },
  {
    id: 5,
    payerPlan: 'Test Plan 1',
  },
  {
    id: 6,
    payerPlan: 'Test Plan 2',
  },
  {
    id: 7,
    payerPlan: 'Test Plan 3',
  },
  {
    id: 8,
    payerPlan: 'Test Plan 4',
  },
];

export const PHASE_STATUS_REASON_OPTIONS = [
  {
    id: 1,
    phaseStatusReason: 'Follow Up',
  },
  {
    id: 2,
    phaseStatusReason: 'Denied',
  },
  {
    id: 3,
    phaseStatusReason: 'Bundled Services',
  },
  {
    id: 4,
    phaseStatusReason: 'Catch All',
  },
  {
    id: 5,
    phaseStatusReason: 'Duplicate',
  },
  {
    id: 6,
    phaseStatusReason: 'Timely Filing',
  },
];

export const FAKE_WORK_GAP_DATA = [
  {
    user: 'Test User 1',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 2',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 3',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Auser whose name starts with a',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'B user',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Baaaab User',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Quser',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'abcdefghijklmnopqrstuvwxyz',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 9',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'a-user-here',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: '@#re',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: '9023 user',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: '†∞∆¥ some special characters',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'B∑ahsd',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 15',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 16',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 17',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 18',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 19',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 20',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 21',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 22',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 23',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 24',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 25',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 26',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 27',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 28',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 29',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 30',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 31',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 32',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 33',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 34',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 35',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 36',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 37',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 38',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 39',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 40',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 41',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 42',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 43',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 44',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 45',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 46',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 47',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 48',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 49',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 50',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 51',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 52',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 53',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 54',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 55',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 56',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 57',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 58',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 59',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 60',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 61',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 62',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 63',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 64',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 65',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 66',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 67',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 68',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 69',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 70',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 71',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 72',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 73',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 74',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 75',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 76',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 77',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 78',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 79',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 80',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 81',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 82',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 83',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 84',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 85',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 86',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 87',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 88',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 89',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 90',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 91',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 92',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 93',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 94',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 95',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 96',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 97',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 98',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 99',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 100',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 101',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 102',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 103',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 104',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 105',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 106',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 107',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 108',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 109',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
];

export const FAKE_ACTIVITY_DATA = Array.from({ length: 143 }, (_, index) => ({
  date: getFakeDate(),
  user_name: 'test_user_' + Math.floor(index / 15),
  activity_type: 'Placeholder',
  activity_code: getFakeActivityCode(),
  account_number: getFakeNumberSeq(9),
  visit_number: getFakeNumberSeq(6),
  current_balance: getFakeNumberSeq(4),
  phase: getRandomPhase(),
  status: getRandomStatus(),
  reason: getRandomReason(),
  score: getFakeNumberSeq(2),
  current_fin_class: getRandomFinClass(),
  current_payer: getRandomPayer(),
  current_plan: getRandomPlan(),
  age_category: getRandomAgeCategory(),
}));

export const FAKE_AR_DATA = Array.from({ length: 133 }, (_, index) => ({
  rsn: getFakeNumberSeq(9),
  mrn: getFakeNumberSeq(6),
  accountNumber: getFakeNumberSeq(6),
  visitNumber: getFakeNumberSeq(6),
  claimNumber: getFakeNumberSeq(6),
  currentBalance: getFakeDollarAmount(),
  totalCharges: getFakeDollarAmount(),
  totalPayments: getFakeDollarAmount(),
  totalAdjustments: getFakeDollarAmount(),
  currentFinancialClass: getRandomFinClass(),
  currentPayer: 'Placeholder',
  currentPlan: 'Placeholder',
  primaryFinancialClass: 'Placeholder',
  primaryPayer: getRandomPayer(),
  primaryPlan: getRandomPlan(),
  phase: 'Placeholder',
  status: 'Placeholder',
  reason: getRandomReason(),
  score: 'Placeholder',
  billingType: 'Placeholder',
  billingEntity: 'Placeholder',
  visitType: 'Placeholder',
  locationName: 'Placeholder',
  departmentName: 'Placeholder',
  billingProviderName: 'Placeholder',
  billingProviderNpi: 'Placeholder',
  diagnosisCode: 'Placeholder',
  serviceStartDate: getFakeNumberDate(),
  serviceEndDate: getFakeNumberDate(),
  ageCategory: getRandomAgeCategory(),
  appealDeadline: 'Placeholder',
  assignedWorklist: 'Placeholder',
  lastDenialDate: getFakeNumberDate(),
  lastPaymentDate: getFakeNumberDate(),
  lastClaimDate: getFakeNumberDate(),
  lastActivityDate: getFakeNumberDate(),
  lastActivityUser: 'Placeholder',
  lastActivityType: 'Placeholder',
  lastActivitySubtype: 'Placeholder',
  lastNote: 'Placeholder',
}));

export const FAKE_AR_BREAKOUT_DATA = Array.from(
  { length: 143 },
  (_, index) => ({
    date: getFakeDate(),
    user: getRandomUser(),
    activity_type: 'Placeholder',
    activity_code: getFakeActivityCode(),
    account_number: getFakeNumberSeq(9),
    visit_number: getFakeNumberSeq(6),
    current_balance: getFakeNumberSeq(4),
    phase: getRandomPhase(),
    status: getRandomStatus(),
    reason: getRandomReason(),
    score: getFakeNumberSeq(2),
    location: getRandomLocation(),
    department: getRandomDepartment(),
    billing_provider: getRandomBillingProvider(),
    billing_entity: getRandomBillingEntity(),
    financial_class: getRandomFinClass(),
    current_payer: getRandomPayer(),
    current_plan: getRandomPlan(),
    age_category: getRandomAgeCategory(),
    balance_bucket: getRandomUser(),
  })
);

export const FAKE_DENIALS_DATA = Array.from({ length: 143 }, (_, index) => ({
  mrn: getFakeDate(),
  account_number: getFakeNumberSeq(9),
  visit_number: getFakeNumberSeq(6),
  denial_amount: getFakeNumberSeq(2),
  denial_post_date: getFakeDate(),
  carc: 'Placeholder',
  carc_description: 'Placeholder',
  rarc: 'Placeholder',
  rarc_description: 'Placeholder',
  denial_category: getRandomDenialCategory(),
  denial_code: getRandomDenialCode(),
  denial_grouping: getRandomDenialGrouping(),
  action_financial: 'Placeholder',
  action_player: 'Placeholder',
  action_plan: 'Placeholder',
  billing_type: 'Placeholder',
  location: getRandomLocation(),
  department: getRandomDepartment(),
  billing_provider: getRandomBillingProvider(),
  billing_entity: getRandomBillingEntity(),
  financial_class: getRandomFinClass(),
  current_payer: getRandomPayer(),
  current_plan: getRandomPlan(),
  procedure_code: 'Placeholder',
  procedure_name: 'Placeholder',
  service_start_date: getFakeDate(),
  service_end_date: getFakeDate(),
}));
