export const arDetailHeaders = [
  { key: 'rsn', value: 'RSN', width: '8rem', sort: true },
  { key: 'mrn', value: 'MRN', width: '8rem', sort: true },
  { key: 'accountNumber', value: 'Account Number', width: '10rem', sort: true },
  { key: 'visitNumber', value: 'Visit Number', width: '10rem', sort: true },
  { key: 'claimNumber', value: 'Claim Number', width: '10rem', sort: true },
  {
    key: 'currentBalance',
    value: 'Current Balance',
    width: '10rem',
    sort: true,
  },
  { key: 'totalCharges', value: 'Total Charges', width: '10rem', sort: true },
  { key: 'totalPayments', value: 'Total Payments', width: '10rem', sort: true },
  {
    key: 'totalAdjustments',
    value: 'Total Adjustments',
    width: '10rem',
    sort: true,
  },
  {
    key: 'currentFinancialClass',
    value: 'Current Financial Class',
    width: '12rem',
    sort: true,
  },
  { key: 'currentPayer', value: 'Current Payer', width: '12rem', sort: true },
  { key: 'currentPlan', value: 'Current Plan', width: '12rem', sort: true },
  {
    key: 'primaryFinancialClass',
    value: 'Primary Financial Class',
    width: '12rem',
    sort: true,
  },
  { key: 'primaryPayer', value: 'Primary Payer', width: '12rem', sort: true },
  { key: 'primaryPlan', value: 'Primary Plan', width: '12rem', sort: true },
  { key: 'phase', value: 'Phase', width: '8rem', sort: true },
  { key: 'status', value: 'Status', width: '8rem', sort: true },
  { key: 'reason', value: 'Reason', width: '10rem', sort: true },
  { key: 'score', value: 'Score', width: '8rem', sort: true },
  { key: 'billingType', value: 'Billing Type', width: '10rem', sort: true },
  { key: 'billingEntity', value: 'Billing Entity', width: '12rem', sort: true },
  { key: 'visitType', value: 'Visit Type', width: '12rem', sort: true },
  { key: 'locationName', value: 'Location Name', width: '12rem', sort: true },
  {
    key: 'departmentName',
    value: 'Department Name',
    width: '12rem',
    sort: true,
  },
  {
    key: 'billingProviderName',
    value: 'Billing Provider Name',
    width: '12rem',
    sort: true,
  },
  {
    key: 'billingProviderNpi',
    value: 'Billing Provider NPI',
    width: '12rem',
    sort: true,
  },
  { key: 'diagnosisCode', value: 'Diagnosis Code', width: '10rem', sort: true },
  {
    key: 'serviceStartDate',
    value: 'Service Start Date',
    width: '12rem',
    sort: true,
  },
  {
    key: 'serviceEndDate',
    value: 'Service End Date',
    width: '12rem',
    sort: true,
  },
  { key: 'ageCategory', value: 'Age Category', width: '10rem', sort: true },
  {
    key: 'appealDeadline',
    value: 'Appeal Deadline',
    width: '12rem',
    sort: true,
  },
  {
    key: 'assignedWorklist',
    value: 'Assigned Worklist',
    width: '12rem',
    sort: true,
  },
  {
    key: 'lastDenialDate',
    value: 'Last Denial Date',
    width: '12rem',
    sort: true,
  },
  {
    key: 'lastPaymentDate',
    value: 'Last Payment Date',
    width: '12rem',
    sort: true,
  },
  {
    key: 'lastClaimDate',
    value: 'Last Claim Date',
    width: '12rem',
    sort: true,
  },
  {
    key: 'lastActivityDate',
    value: 'Last Activity Date',
    width: '12rem',
    sort: true,
  },
  {
    key: 'lastActivityUser',
    value: 'Last Activity User',
    width: '12rem',
    sort: true,
  },
  {
    key: 'lastActivityType',
    value: 'Last Activity Type',
    width: '12rem',
    sort: true,
  },
  {
    key: 'lastActivitySubtype',
    value: 'Last Activity Subtype',
    width: '12rem',
    sort: true,
  },
  { key: 'lastNote', value: 'Last Note', width: '12rem', sort: true },
];
