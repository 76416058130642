<template>
  <span
    class="flex flex-row gap-1 items-center"
    :class="{
      [props.fontClasses]: true,
      [props.sizeClasses]: true,
      ['text-secondary fill-secondary']: colorTernary == 0,
      ['text-bad-red fill-bad-red']: colorTernary == 1,
      ['text-good-green fill-good-green']: colorTernary == 2,
      ['text-black ']: colorTernary == 3,
    }"
  >
    <CaretIcon v-if="caretTernary == 1"></CaretIcon>
    <CaretIcon v-if="caretTernary == 2" :flip="true"></CaretIcon>
    <ArrowUpIcon v-if="arrowTernary == 1"></ArrowUpIcon>
    <ArrowUpIcon v-if="arrowTernary == 2" :flip="true"></ArrowUpIcon>
    {{
      value
        ? currency
          ? formatCurrency('en-US', false).format(value)
          : value + (percentage ? '%' : '')
        : value == 0
        ? 0
        : 'No Data'
    }}
  </span>
</template>
<script setup>
import ArrowUpIcon from '@/assets/misc-icons/ArrowUpIcon.vue';
import CaretIcon from '@/assets/misc-icons/CaretIcon.vue';
import { formatCurrency } from '@/utils/helpers';

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
  percentage: {
    type: Boolean,
    default: false,
  },
  currency: {
    type: Boolean,
    default: false,
  },
  arrowTernary: {
    type: Number,
    default: 0,
  },
  caretTernary: {
    type: Number,
    default: 0,
  },
  fontClasses: {
    type: String,
    default: 'font-bold text-2xl',
  },
  sizeClasses: {
    type: String,
    default: '',
  },
  colorTernary: {
    type: Number,
    default: 0,
  },
});
</script>
