<template>
  <div class="flex flex-col gap-3">
    <div class="flex gap-3">
      <StatsDatePicker
        ref="startDatePicker"
        label="Start Date"
        uniqueIdString="workGapStartDate"
        placeholder="MM/DD/YYYY"
        :model-value="startDate"
        :min-date="new Date(minStartDate)"
        :max-date="new Date(endDate)"
        @update="
          newDate => {
            startDate = newDate;
          }
        "
      />
      <StatsDatePicker
        ref="endDatePicker"
        label="End Date"
        uniqueIdString="workGapEndDate"
        placeholder="MM/DD/YYYY"
        :model-value="endDate"
        :min-date="new Date(startDate)"
        :max-date="new Date()"
        @update="
          newDate => {
            endDate = newDate;
          }
        "
      />
    </div>
    <div class="flex flex-col gap-1">
      <span class="font-bold p-0.5 text-gray-900 text-sm"
        >Additional Criteria</span
      >
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="userDropdownRef"
          unique-id-string="work-gap-user-dropdown"
          variant="secondary"
          text-key="username"
          :options="userDropdownOptions"
          button-text="User"
          @update-selection="
            selection => {
              userSelection = selection.value || selection;
            }
          "
        />
        <DropDownFlowBite
          ref="activityGapDropdownRef"
          unique-id-string="work-gap-activity-gap-dropdown"
          :options="activityGapDropdownOptions"
          text-key="activityGap"
          variant="secondary"
          button-text="Activity Gap"
          button-class="w-full"
          @update-selection="
            selection => {
              activityGapSelection = selection;
            }
          "
        />
      </div>
    </div>
  </div>
  <hr class="h-px my-10 bg-gray-200 border-0 dark:bg-gray-700" />
  <div class="flex flex-row w-1/4 pr-4 self-end gap-2">
    <OutlinedButtonFlowBite
      class="text-gray-900 font-bold"
      @click="resetFilters"
    >
      Reset
    </OutlinedButtonFlowBite>
    <SolidButtonFlowBite
      variant="tertiary"
      class="!font-bold"
      @click="applyFilters"
    >
      Apply
    </SolidButtonFlowBite>
  </div>
</template>

<script setup>
import { useWorkGapStore } from '@/stores/stats/useWorkGapStats';
import { computed, onMounted, ref, watch } from 'vue';
import SolidButtonFlowBite from '@/components/buttons/SolidButtonFlowBite.vue';
import StatsMultiSelectFlowbite from '../StatsMultiSelectFlowbite.vue';
import StatsDatePicker from '../StatsDatePicker.vue';
import OutlinedButtonFlowBite from '@/components/buttons/OutlinedButtonFlowBite.vue';
import DropDownFlowBite from '@/components/forms/DropDownFlowBite.vue';
import { useUsersStore } from '@/stores/useUsers';

const props = defineProps({
  closeModal: {
    type: Function,
    required: true,
  },
});

const workGapStore = useWorkGapStore();
const userStore = useUsersStore();

const userSelection = ref([]);
const userDropdownOptions = ref([]);
const activityGapSelection = ref(0);
const startDate = ref(workGapStore.startDate);
const endDate = ref(workGapStore.endDate);

const userDropdownRef = ref(null);
const activityGapDropdownRef = ref(null);
const startDatePicker = ref(null);
const endDatePicker = ref(null);

const activityGapDropdownOptions = [
  { id: 1, activityGap: '1.5 - 3 hours' },
  { id: 2, activityGap: '3+ hours' },
];

onMounted(async () => {
  await userStore.getAllUsers();
  const allUsersList = userStore.users;
  userDropdownOptions.value = allUsersList.map(user => {
    return {
      id: user.auxo_user_id,
      username: user.first_name + ' ' + user.last_name,
    };
  });
});

const minStartDate = computed(() => {
  const thirteenMonthsAgo = new Date();
  thirteenMonthsAgo.setDate(1);
  thirteenMonthsAgo.setMonth(thirteenMonthsAgo.getMonth() - 13);
  return thirteenMonthsAgo.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
});

const resetFilters = () => {
  userDropdownRef.value.clearSelection();
  activityGapDropdownRef.value.clearSelection();

  startDatePicker.value.clearSelection(workGapStore.defaultStartDate);
  endDatePicker.value.clearSelection(workGapStore.defaultEndDate);
  workGapStore.resetFilters();
};

const applyFilters = async () => {
  workGapStore.applyFilters(
    startDate.value,
    endDate.value,
    userSelection.value,
    activityGapSelection.value
  );
  await workGapStore.fetchMetrics();
  await workGapStore.fetchDetails();
  props.closeModal();
};

// All of theses are in the event the user clears a filter chip
// We need to clear the dropdown selection as well
watch(
  () => workGapStore.selectedActivityGap,
  newVal => {
    if (newVal == 0) {
      activityGapDropdownRef.value?.clearSelection();
    }
  }
);

watch(
  () => workGapStore.selectedUsers,
  newVal => {
    if (newVal.length == 0) {
      userDropdownRef.value?.clearSelection();
    }
  }
);

watch(
  () => workGapStore.startDate,
  newVal => {
    if (newVal == workGapStore.defaultStartDate) {
      startDatePicker.value?.clearSelection(workGapStore.defaultStartDate);
    }
  }
);

watch(
  () => workGapStore.endDate,
  newVal => {
    if (newVal == workGapStore.defaultEndDate) {
      endDatePicker.value?.clearSelection(workGapStore.defaultEndDate);
    }
  }
);
</script>
